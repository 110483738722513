import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ProductList: [],
  totalAmount: 0,
};

export const productSlice = createSlice({
    name: "productList",
    initialState,
    reducers: {
      addProduct: (state, action) => {
        state.ProductList = action.payload.map((item) => item);
      },
    },
  });
  
  export const {
    addProduct,
  } = productSlice.actions;
  
  export default productSlice.reducer;