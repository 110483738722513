import React, { useState, useRef, forwardRef } from "react";
import { Dialog, Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import Logo from "../images/ttmm.png";
// import title from "../images/ttTitle.png";
// import ttMap from "../images/ttMap.png";
import subTitle from "../images/subTitle.png";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { v4 as uuidv4 } from "uuid";
import { useReactToPrint } from "react-to-print";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const VoucherDetailsDialog = ({ open, handleClose, showDetails, print }) => {
  console.log(print);

  const [selectedValuePrint, setSelectedValue] = useState("a");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const componentRef = useRef();
  const componentRefA4 = useRef();
  const componentRefA5 = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrintA5 = useReactToPrint({
    content: () => componentRefA5.current,
  })

  const handlePrintA4 = useReactToPrint({
    content: () => componentRefA4.current,
    // pageStyle: () => getPageMargins,
  });



  const isEmptyObj = (obj) => {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }

    return true;
  };

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Voucher Details
            </Typography>
            <div className="flex flew-row">
              <div>
                <label>A4</label>
                <Radio
                  checked={selectedValuePrint === "a"}
                  onChange={handleChange}
                  value="a"
                  color={"error"}
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
              </div>
              <div>
                <label>A5</label>
                <Radio
                  checked={selectedValuePrint === "b"}
                  onChange={handleChange}
                  value="b"
                  color={"error"}
                  name="radio-buttons"
                  inputProps={{ "aria-label": "C" }}
                />
              </div>
              <div>
                <label>Slip</label>
                <Radio
                  checked={selectedValuePrint === "s"}
                  onChange={handleChange}
                  color={"error"}
                  value="s"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "B" }}
                />
              </div>
            </div>
            <Button
              variant="contained"
              onClick={() => {
                selectedValuePrint == "a" ? handlePrintA4() : selectedValuePrint == "b" ? handlePrintA5() : handlePrint();
              }}
            >
              Print Voucher
            </Button>
            <div
              style={{ display: "none" }} // This make ComponentToPrint show   only while printing
            >
              {localStorage.getItem("auth") ? (
                <PrintComponentA4
                  ref={componentRefA4}
                  voucher={{
                    cashier: isEmptyObj(print) ? "" : print?.customer[0]?.name,
                    adds: isEmptyObj(print) ? "" : print?.customer[0]?.address,
                    id: isEmptyObj(print) ? "" : print?.id,
                    phone: isEmptyObj(print) ? "" : print?.customer[0]?.phone,
                  }}
                  items={showDetails}
                  summary={{
                    total: isEmptyObj(print) ? "0" : print?.total,
                    bill: isEmptyObj(print) ? "0" : print?.bill,
                    oldBill: "-",
                    discount: isEmptyObj(print) ? "0" : print?.discount,
                    cash:
                      parseInt(print?.total) -
                      parseInt(print?.bill) -
                      parseInt(print?.discount),
                  }}
                />
              ) : (
                <></>
              )}
            </div>

            <div
              style={{ display: "none" }} // This make ComponentToPrint show   only while printing
            >
              {localStorage.getItem("auth") ? (
                <PrintComponentA5
                  ref={componentRefA5}
                  voucher={{
                    cashier: isEmptyObj(print) ? "" : print?.customer[0]?.name,
                    adds: isEmptyObj(print) ? "" : print?.customer[0]?.address,
                    id: isEmptyObj(print) ? "" : print?.id,
                    phone: isEmptyObj(print) ? "" : print?.customer[0]?.phone,
                  }}
                  items={showDetails}
                  summary={{
                    total: isEmptyObj(print) ? "0" : print?.total,
                    bill: isEmptyObj(print) ? "0" : print?.bill,
                    oldBill: "-",
                    discount: isEmptyObj(print) ? "0" : print?.discount,
                    cash:
                      parseInt(print?.total) -
                      parseInt(print?.bill) -
                      parseInt(print?.discount),
                  }}
                />
              ) : (
                <></>
              )}
            </div>

            <div
              style={{ display: "none" }} // This make ComponentToPrint show   only while printing
            >
              {localStorage.getItem("auth") ? (
                <PrintComponent
                  ref={componentRef}
                  voucher={{
                    cashier: isEmptyObj(print) ? "" : print?.customer[0]?.name,
                    adds: isEmptyObj(print) ? "" : print?.customer[0]?.address,
                    id: isEmptyObj(print) ? "" : print?.id,
                    phone: isEmptyObj(print) ? "" : print?.customer[0]?.phone,
                  }}
                  items={showDetails}
                  summary={{
                    total: isEmptyObj(print) ? "0" : print?.total,
                    bill: isEmptyObj(print) ? "0" : print?.bill,
                    oldBill: "-",
                    discount: isEmptyObj(print) ? "0" : print?.discount,
                    cash:
                      parseInt(print?.total) -
                      parseInt(print?.bill) -
                      parseInt(print?.discount),
                  }}
                />
              ) : (
                <></>
              )}
            </div>
            <Button autoFocus color="inherit" onClick={handleClose}>
              OK
            </Button>
          </Toolbar>
        </AppBar>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Barcode</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Qty</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {showDetails.map((detail, i) => (
                <TableRow
                  key={uuidv4()}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {i + 1}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {detail?.product.barcode}
                  </TableCell>
                  <TableCell> {detail?.product.name} </TableCell>
                  <TableCell> {detail?.qty} </TableCell>
                  <TableCell> {detail?.sell_price}</TableCell>
                  <TableCell>{detail?.qty * detail?.sell_price}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Dialog>
    </>
  );
};

const PrintComponent = forwardRef((props, ref) => {
  const { voucher, items, summary } = props;
  console.log(props);
  console.log("Testing");
  // const [i, setI] = useState({});
  var today = new Date();
  const date =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1) +
    "-" +
    today.getDate() +
    "-" +
    today.getHours() +
    ":" +
    today.getMinutes() +
    ":" +
    today.getSeconds();

  const shop = JSON.parse(localStorage.getItem("shopdetails"));

  function currencyFormat(num) {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  const phoneList = shop.phone.split(",");

  return (
    <>
      <div
        id="divToPrint"
        className="flex flex-col w-[303px] h-fit text-center"
        ref={ref}
      >
        <div className="flex flex-row w-full h-fit pt-2 justify-center items-center">
          {shop.id == 1 ? (
            <img src={Logo} alt="slip" width={150} height={50} />
          ) : (
            <>
              <img src={Logo} alt="slip" width={150} height={50} />
            </>
          )}
        </div>

        <div className="flex flex-col w-full h-fit py-2 justify-center items-center">
          {/* <p className="font-bold font-poppins text-base">ပေါ်ပြူလာ</p> */}
          <p className="font-bold font-poppins text-xs px-8">
            စပါးရိတ်သိမ်းခြွေလှေ့စက် ၊ ထွန်စက် နှင့် အပိုပစ္စည်း အရောင်းဆိုင်
          </p>
          {/* <p className="font-bold font-poppins text-xs">
            ဆိုင်အမှတ် ပ-၂၄၅၊ နေပြည်တော်မြို့မဈေးမြောက်ဘက်တန်း၊
          </p> */}

          <div className="grid grid-cols-3 gap-1">
            {phoneList.map((phone) => (
              <p className="font-poppins text-xs text-balance w-full text-center justify-center items-center mx-auto" key={uuidv4().toString()}>
                {phone}
              </p>
            ))}
          </div>
          <p className="font-bold font-poppins text-sm">{shop.address}</p>
        </div>
        <div className="flex flex-row w-full justify-start items-start">
          <div className="flex-1 flex-col h-fit px-2 justify-start items-start">
            <p className="font-poppins text-xs text-start">
              <span className="text-xs pr-2">အမည်၊</span>
              {voucher.cashier}
            </p>
            <p className="font-poppins text-xs text-start">
              <span className="text-xs pr-2">နေရပ်၊</span>
              {voucher.adds}
            </p>
          </div>

          <div className="flex flex-col w-fit h-fit px-2 justify-end items-end">
            <p className="font-poppins text-xs">
              <span className="text-xs">ရက်စွဲ၊ </span>
              {today.getDate() +
                "-" +
                (today.getMonth() + 1) +
                "-" +
                today.getFullYear()}
            </p>
          </div>
        </div>

        <div className="flex flex-row w-full h-fit px-2 pt-4">
          <div className="flex-1 text-xs text-start">အမျိုးအမည်</div>
          <div className="w-8 text-xs">ဦးရေ</div>
          <div className="text-xs w-16">နှုန်း</div>
          <div className="text-xs w-20">သင့်ငွေ</div>
        </div>
        <div>
          --------------------------------------------------------------
        </div>

        {items.map((item, index) => (
          <div className="flex flex-row w-full h-fit py-2 pr-2" key={uuidv4()}>
            <div className="flex-1 text-xs">{item.product.name}</div>
            <div className="w-8 text-xs">{item.qty}</div>
            <div className="text-xs w-16">{item.sell_price}</div>
            <div className="text-xs w-20">{item.qty * item.sell_price}</div>
          </div>
        ))}

        <div>-------------------------------------------------------------</div>
        <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-xs">
          <span>ကျသင့်ငွေ </span>
          {summary.total} ကျပ်
        </div>
        <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-xs">
          <span>လျော့ပေးငွေ </span>
          {summary.discount} ကျပ်
        </div>
        <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-xs">
          <span>ပေးချေငွေ </span>
          {Math.floor(summary.cash)} ကျပ်
        </div>
        <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-xs">
          <span>ယခင် အကြွေးကျန် </span>
          {summary.oldBill} ကျပ်
        </div>
        <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-xs">
          <span>ယခု အကြွေးကျန် </span>
          {summary.bill} ကျပ်
        </div>

        <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-xs">
          <span>စုစုပေါင်း အကြွေးကျန် </span>- ကျပ်
        </div>
        <div className="w-full h-fit text-xs text-center mt-4">
          ⚠️ ဝယ်ယူပြီး ပစ္စည်းပြန်မလဲပေးပါ။ ⚠️
        </div>
        <div className="w-full h-fit text-xs text-center mt-4">
          🤝 ဝယ်ယူအားပေးမှုကို အထူးကျေးဇူးတင်ရှိပါသည်။ 🤝
        </div>
        <div className="flex flex-row w-full py-2 px-2 justify-between font-poppins text-xs mb-10">
          <span>Print Time </span>
          {date}
        </div>
        <div>
          --------------------------
          <span className="transform rotate-270 font-bold">✂</span>
          ---------------------------------
        </div>
      </div>
    </>
  );
});

const PrintComponentA4 = forwardRef((props, ref) => {
  const { voucher, items, summary } = props;
  const user = JSON.parse(localStorage.getItem("user"));
  console.log(props);
  console.log("Testing");
  // const [i, setI] = useState({});
  var today = new Date();
  const date =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1) +
    "-" +
    today.getDate() +
    "-" +
    today.getHours() +
    ":" +
    today.getMinutes() +
    ":" +
    today.getSeconds();

  const shop = JSON.parse(localStorage.getItem("shopdetails"));

  function currencyFormat(num) {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1");
  }

  const phoneList = shop.phone.split(",");
  const getPageMargins = () => {
    return `@page { margin: 20px !important; }`;
  };

  return (
    <>
      <div
        id="divToPrint"
        className="flex flex-col h-fit text-center relative"
        ref={ref}
      >
        <style>{getPageMargins()}</style>
        <div className="flex flex-col w-full h-fit py-2 justify-center items-center sm:text-xs">
          <div className="flex flex-row w-full justify-center items-center">
            {/* <img src={ttMap} alt="logo" width={80} height={80} className="" /> */}
            <div className="w-full">
              <div className="flex flex-row w-full h-fit justify-center items-center">
                <img src={Logo} alt="slip" className="h-32 w-full" />
              </div>
              <div className="flex flex-row w-full h-fit justify-center items-center">
                <img src={subTitle} alt="slip" className=" h-16 w-full" />
              </div>
              {/* <p className="font-bold font-poppins my-2 text-3xl">
                စပါးရိတ်သိမ်းခြွေလှေ့စက်၊ထွန်စက်နှင့်အပိုပစ္စည်းအရောင်းဆိုင်
              </p> */}
              <div className="grid grid-cols-4 gap-1 mt-4">
                {phoneList.map((phone) => (
                  <p key={uuidv4().toString()} className="font-bold font-poppins text-[1.3rem] text-balance w-full text-center justify-center items-center mx-auto">
                    {phone}
                  </p>
                ))}
              </div>
              <p className="font-bold font-poppins text-xl pt-1">
                {shop.address}
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-row w-full h-fit justify-between items-start">
          <div className="flex flex-col justify-between items-start w-1/3">
            <p className="font-poppins text-sm flex flex-row w-full  justify-between items-center">
              <span className="text-sm pr-2">Voucher ID</span>
              <p>{voucher.id}</p>
            </p>
            <p className="font-poppins text-sm flex flex-row w-full justify-between items-center">
              <span className="text-sm pr-2">Cashier  </span>
              {user.name}
            </p>
            <p className="font-poppins text-sm flex flex-row w-full justify-between items-center">
              <span className="text-sm">Date  </span>
              <p>
                {today.getFullYear() +
                  "-" +
                  (today.getMonth() + 1) +
                  "-" +
                  today.getDate()}
              </p>
            </p>
          </div>

          <div className="flex flex-row justify-end grow-0">
            <div className="flex flex-col">
              <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
                <span className="text-sm pr-6 w-24 text-start">အမည်</span>
              </p>
              <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
                <span className="text-sm pr-6 w-24 text-start">လိပ်စာ</span>
              </p>
              <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
                <span className="text-sm pr-6 w-24 text-start">
                  ဖုန်းနံပါတ်
                </span>
              </p>
            </div>
            <div className="flex-1 flex-col justify-between">
              <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
                {voucher.cashier}
              </p>
              <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
                {voucher.adds}
              </p>
              <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
                {voucher.phone}
              </p>
            </div>
          </div>
        </div>

        <div className="w-full mt-4">
        <table className="w-full text-sm text-left text-black border border-black border-collapse py-5">
            <thead className="text-lg text-center text-white uppercase bg-blue-600 my-header">
              <tr>
                <th
                  scope="col"
                  className="px-2 py-3 text-lg border border-black border-collapse"
                >
                    No.
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-lg border border-black border-collapse"
                >
                  Product Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-lg border border-black border-collapse"
                >
                  Qty
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 border border-black border-collapse text-lg"
                >
                  Price
                </th>
                
                <th
                  scope="col"
                  className="px-6 py-3 border border-black border-collapse text-lg"
                >
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr className="border border-black border-collapse" key={uuidv4().toString()}>
                  <td
                    scope="row"
                    className="px-2 py-1 border border-black border-collapse text-center"
                  >
                    {index + 1}
                  </td>
                  <td className="px-2 py-1 border border-black border-collapse">
                    {item.product.name}
                  </td>
                  <td className="px-2 py-1 border border-black border-collapse text-center">
                    {item.qty}
                  </td>
                  <td className="px-2 py-1 border border-black border-collapse text-right">
                    {currencyFormat(parseInt(item.sell_price))}
                  </td>
                  
                  <td className="px-2 py-1 border border-black border-collapse text-right">
                    {currencyFormat(parseInt(item.qty * item.sell_price))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className=" flex flex-row w-full justify-end text-base">
          <div className=" flex flex-col w-1/2 justify-end items-end">
            <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base">
              <span className="text-start w-[60%]">စုစုပေါင်းငွေ </span>{" "}
              {summary.total}
            </div>
            <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base ">
              <span className="text-start w-[60%]">လျော့ပေးငွေ </span>
              {summary.discount}
            </div>
            <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base">
              <span className="text-start w-[60%]">ပေးချေငွေ </span>
              {summary.cash}
            </div>
            <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base">
              <span className="text-start w-[60%]"> ယခင်ကြွေးကျန် </span>{" "}
              {summary.oldBill}
            </div>
            <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base ">
              <span className="text-start w-[60%]"> ယခုကြွေးကျန် </span>
              {summary.bill}
            </div>
            <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base ">
              <span className="text-start w-[60%]">စုစုပေါင်းကြွေးကျန် </span>{" "}
              {" - "}
            </div>
          </div>
        </div>

        <div className="w-full h-fit text-lg text-center mt-4">
          🤝 ဝယ်ယူအားပေးမှုကို အထူးကျေးဇူးတင်ရှိပါသည်။ 🤝
        </div>

        <div className="w-full h-fit text-lg text-center mt-4">
          ⚠️ ဝယ်ယူပြီး ပစ္စည်းပြန်မလဲပေးပါ။ ⚠️
        </div>

        <div className="flex flex-row w-full justify-between font-poppins text-base mb-10">
          <span>Print Time </span>
          {date}
        </div>
        <div>
          --------------------------
          <span className="transform rotate-270 font-bold">✂</span>
          ---------------------------------
        </div>
      </div>
    </>
  );
});

const PrintComponentA5 = forwardRef((props, ref) => {
  const { voucher, items, summary } = props;
  const user = JSON.parse(localStorage.getItem("user"));
  console.log(props);
  console.log("Testing");
  // const [i, setI] = useState({});
  var today = new Date();
  const date =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1) +
    "-" +
    today.getDate() +
    "-" +
    today.getHours() +
    ":" +
    today.getMinutes() +
    ":" +
    today.getSeconds();

  const shop = JSON.parse(localStorage.getItem("shopdetails"));

  function currencyFormat(num) {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1");
  }

  const phoneList = shop.phone.split(",");
  const getPageMargins = () => {
    return `@page { margin: 20px !important; }`;
  };

  return (
    <>
      <div
        id="divToPrint"
        className="flex flex-col h-fit text-center relative"
        ref={ref}
      >
        <style>{getPageMargins()}</style>
        <div className="flex flex-col w-full h-fit py-2 justify-center items-center sm:text-xs">
          <div className="flex flex-row w-full justify-center items-center">
            {/* <img src={ttMap} alt="logo" width={80} height={80} className="" /> */}
            <div className="w-full">
              <div className="flex flex-row w-full h-fit justify-center items-center">
                <img src={Logo} alt="slip" className="h-24 w-full" />
              </div>
              {/* <p className="font-bold font-poppins text-[20px]">
                စပါးရိတ်သိမ်းခြွေလှေ့စက်၊ထွန်စက်နှင့်အပိုပစ္စည်းအရောင်းဆိုင်
              </p> */}
              <div className="flex flex-row w-full h-fit justify-center items-center">
                <img src={subTitle} alt="slip" className=" h-10 w-full" />
              </div>
              <div className="grid grid-cols-4 gap-1 mt-4">
                {phoneList.map((phone) => (
                  <p key={uuidv4().toString()} className="font-bold font-poppins text-[1rem] text-balance w-full text-center justify-center items-center mx-auto">
                    {phone}
                  </p>
                ))}
              </div>
              <p className="font-bold font-poppins text-xl pt-1">
                {shop.address}
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-row w-full h-fit justify-between items-start">
          <div className="flex flex-col justify-between items-start w-1/3">
            <p className="font-poppins text-sm flex flex-row w-full  justify-between items-center">
              <span className="text-sm pr-2">Voucher ID</span>
              <p>{voucher.id}</p>
            </p>
            <p className="font-poppins text-sm flex flex-row w-full justify-between items-center">
              <span className="text-sm pr-2">Cashier - </span>
              {user.name}
            </p>
            <p className="font-poppins text-sm flex flex-row w-full justify-between items-center">
              <span className="text-sm">Date - </span>
              <p>
                {today.getFullYear() +
                  "-" +
                  (today.getMonth() + 1) +
                  "-" +
                  today.getDate()}
              </p>
            </p>
          </div>

          <div className="flex flex-row justify-end grow-0">
            <div className="flex flex-col">
              <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
                <span className="text-sm pr-6 w-24 text-start">အမည်</span>
              </p>
              <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
                <span className="text-sm pr-6 w-24 text-start">လိပ်စာ</span>
              </p>
              <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
                <span className="text-sm pr-6 w-24 text-start">
                  ဖုန်းနံပါတ်
                </span>
              </p>
            </div>
            <div className="flex-1 flex-col justify-between">
              <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
                {voucher.cashier}
              </p>
              <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
                {voucher.adds}
              </p>
              <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
                {voucher.phone}
              </p>
            </div>
          </div>
        </div>

        <div className="w-full mt-4">
        <table className="w-full text-sm text-left text-black border border-black border-collapse py-5">
            <thead className="text-base text-center text-white uppercase bg-blue-600 my-header">
              <tr>
                <th
                  scope="col"
                  className="px-4 py-3 border border-black border-collapse"
                >
                    No.
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 border border-black border-collapse text-base"
                >
                  Product Name
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 border border-black border-collapse"
                >
                  Qty
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 border border-black border-collapse"
                >
                  Price
                </th>
                
                <th
                  scope="col"
                  className="px-6 py-3 border border-black border-collapse"
                >
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr className="border border-black border-collapse" key={uuidv4().toString()}>
                  <td
                    scope="row"
                    className="px-4 py-1 border border-black border-collapse text-center"
                  >
                    {index + 1}
                  </td>
                  <td className="px-2 py-1 border border-black border-collapse">
                    {item.product.name}
                  </td>
                  <td className="px-2 py-1 border border-black border-collapse text-center">
                    {item.qty}
                  </td>
                  <td className="px-2 py-1 border border-black border-collapse text-right">
                    {currencyFormat(parseInt(item.sell_price))}
                  </td>
                  
                  <td className="px-2 py-1 border border-black border-collapse text-right">
                    {currencyFormat(parseInt(item.qty * item.sell_price))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className=" flex flex-row w-full justify-end text-base">
          <div className=" flex flex-col w-1/2 justify-end items-end">
            <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base">
              <span className="text-start w-[60%]">စုစုပေါင်းငွေ </span>{" "}
              {summary.total}
            </div>
            <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base ">
              <span className="text-start w-[60%]">လျော့ပေးငွေ </span>
              {summary.discount}
            </div>
            <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base">
              <span className="text-start w-[60%]">ပေးချေငွေ </span>
              {summary.cash}
            </div>
            <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base">
              <span className="text-start w-[60%]"> ယခင်ကြွေးကျန် </span>{" "}
              {summary.oldBill}
            </div>
            <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base ">
              <span className="text-start w-[60%]"> ယခုကြွေးကျန် </span>
              {summary.bill}
            </div>
            <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base ">
              <span className="text-start w-[60%]">စုစုပေါင်းကြွေးကျန် </span>{" "}
              {" - "}
            </div>
          </div>
        </div>

        <div className="w-full h-fit text-lg text-center mt-4">
          🤝 ဝယ်ယူအားပေးမှုကို အထူးကျေးဇူးတင်ရှိပါသည်။ 🤝
        </div>

        <div className="w-full h-fit text-lg text-center mt-4">
          ⚠️ ဝယ်ယူပြီး ပစ္စည်းပြန်မလဲပေးပါ။ ⚠️
        </div>

        <div className="flex flex-row w-full justify-between font-poppins text-base mb-10">
          <span>Print Time </span>
          {date}
        </div>
        <div>
          --------------------------
          <span className="transform rotate-270 font-bold">✂</span>
          ---------------------------------
        </div>
      </div>
    </>
  );
});

export default VoucherDetailsDialog;

