import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";


const StackChart = ({ report }) => {
  console.log(report);

  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    setGraphData(
      report?.map((rp) => {
        const extra = rp.expense.length;
        const exp = extra === 0 ? 0 : rp.expense[0].expense_amount;
        const inc = extra === 0 ? 0 : rp.expense[0].income_amount;
        return {
          month: rp.month_year,
          sale: rp.saleamount,
          bill: rp.billamount,
          expense: exp,
          total:
            parseInt(rp.saleamount) -
            parseInt(rp.billamount) -
            parseInt(exp) +
            parseInt(inc),
        };
      })
    );
  }, [report]);

  return (
    <div className="w-full flex flex-col md:flex-row py-6 bg-gray-200 my-12 rounded-md shadow-md">
      <div style={{ width: "100%", height: 400 }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={graphData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="total"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
            <Line type="monotone" dataKey="sale" stroke="#82ca9d" />
            <Line type="monotone" dataKey="bill" stroke="#ffaa3c" />
            <Line type="monotone" dataKey="expense" stroke="#ff1a3c" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default StackChart;
