import React from "react";
import Image from "../../images/disconnect.png";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
const TImeOutConnection = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-row w-full h-full justify-center items-start select-none text-center">
      <div className="flex flex-col">
        <span className="text-3xl font-bold font-poppins text-gray-600">Oops!....   Network Problem..</span>
        <span className="mx-10 my-6 font-poppins font-semibold text-gray-700">Check Your Internet Connection......</span>
        <div className="">
          <img
            src={Image}
            alt="no-connection"
            className="object-contain"
            width={800}
          />
        </div>
        <Button variant="contained" onClick={()=>navigate(-1)}>
          Reload
        </Button>
      </div>
    </div>
  );
};

export default TImeOutConnection;
