import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Avatar, Spinner } from "@material-tailwind/react";
import { useGetProdcutByStoreQuery } from "../../ApiService/ProductApiSlice";
import DetailDialog from "../../Components/DetailDialog";
import { useNavigate } from "react-router-dom";

const ProductList = () => {
  const navigate = useNavigate();
  
  useEffect(()=>{
    const auth = localStorage.getItem('auth');
    if(!auth){
      navigate("/login");
    }
  },[])

  const {
    data: products,
    isLoading: productsLoading,
    isSuccess: productsSuccess,
  } = useGetProdcutByStoreQuery(localStorage.getItem("shop_id"));

  const [lstProducts, setProductList] = useState([]);
  const [open, setOpen] = useState(false);
  const [detial, setDetail] = useState({});

  const columns = productsSuccess
    ? [
        { field: "No", headerName: "No.", width: 50, headerAlign: 'center', align: 'center' },
        {
          field: "img",
          headerName: "Image",
          width: 70,
          editable: false,
          headerAlign: 'center',
          align: 'center',
          renderCell: (params) => (
            <Avatar
              src={`https://warehouse.futuretechmm.net/pos-vendor-backend/public/${params.value}`}
              className="w-16 h-16 border-blue-gray-50 bg-blue-gray-50/50 object-contain"
              variant="rounded"
            />
          ),
        },
        {
          field: "name",
          headerName: "Product Name",
          flex: 1,
          headerAlign: 'left',
          align: 'left'
        },
        {
          field: "barcode",
          headerName: "Barcode",
          width: 100,
          headerAlign: 'center',
          align: 'center'
        },
        {
          field: "categoryName",
          headerName: "Category",
          width: 70,
          headerAlign: 'center',
          align: 'center'
        },
        {
          field: "rem_qty",
          headerName: "Stock",
          sortable: true,
          width: 70,
          headerAlign: 'center',
          align: 'center'
        },
        {
          field: "alert_qty",
          headerName: "Alert Qty",
          sortable: true,
          width: 70,

          headerAlign: 'center',
          align: 'center'
        },
        {
          field: "unitName",
          headerName: "Unit",
          width: 80,

          headerAlign: 'center',
          align: 'center'
        },

        {
          field: "sell_price1",
          headerName: "Sell Price",
          width: 80,

          headerAlign: 'center',
          align: 'center'
        },
        // {
        //   field: "sell_price2",
        //   headerName: "Sell Price2",
        //   flex: 1,
        // },
        // {
        //   field: "sell_price3",
        //   headerName: "Sell Price3",
        //   flex: 1,
        // },
      ]
    : [];

  const showProductDetails = (detialItem) => {
    setDetail(detialItem);
    setOpen(true);
  };

  useEffect(() => {
    if(productsSuccess){
      setProductList(
      products?.data?.map((p, i) => {
        return {
          No: i + 1,
          id: p.product.id,
          img: p.product.img,
          rem_qty: p.qty,
          currency: p.product.currency.ChangePrice,
          name: p.product.name,
          categoryName: p.product.category.name,
          alert_qty: p.product.alert_qty,
          unitName: p.product.unit.name,
          barcode: p.product.barcode,
          sell_price1: Math.ceil((((p.product.purchase.price + (p.product.purchase.price * (p.product.remark)/100)) * p.product.currency.ChangePrice) / 100)) * 100,
          sell_price2: p.product.sell_price2,
          sell_price3: p.product.sell_price3,
          purchase: p.product.purchase,
        };
      })
    );
    }
    
  }, [products,productsSuccess]);


  return (
    <div>
      <div className="flex flex-col w-full">
        <Box sx={{ height: 600, width: "100%" }}>
          {!productsLoading ? (
            productsSuccess && (
              <DataGrid
                rows={lstProducts}
                loading={productsLoading}
                getRowClassName={(params) => {
                  return params.row.rem_qty < params.row.alert_qty
                    ? "text-red-400"
                    : "";
                }}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                onRowDoubleClick={(params) => showProductDetails(params.row)}
                pageSizeOptions={[5,10,25,50,100]}
                disableRowSelectionOnClick
                slots={{ toolbar: GridToolbar }}
                showCellVerticalBorder
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
                //loading
              />
            )
          ) : (
            <div className="flex flex-row w-full h-full justify-center items-center">
              <Spinner color="purple" className="w-16 h-16" />
            </div>
          )}
        </Box>
      </div>
      <DetailDialog
        open={open}
        handleClose={() => setOpen(false)}
        showDetails={detial}
      />
    </div>
  );
};

export default ProductList;
