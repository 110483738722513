import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Spinner } from "@material-tailwind/react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { DebitDetailDialog, CustomerDebitVoucherList } from "../../Components";
import { MyToolTip } from "../../Components";
import {
  useGetDebtListQuery,
} from "../../ApiService/SaleVoucherApiSlice";
import { IconButton } from "@material-tailwind/react";
import { MdPreview } from "react-icons/md";
import { FaMoneyCheck } from "react-icons/fa";

const DebtPaidPage = () => {


  const shop = localStorage.getItem("shop_id");
  console.log(shop);
  // const [debtPaid, debtPaidResults] = useUpdateDebtPaymentMutation();
  const [showDetails, setShowDetails] = useState([]);
  const [open, setOpen] = useState(false);
  const [openList, setOpenList] = useState(false);
  const [bill, setBill] = useState({});
  const {
    data,
    isSuccess,
    isLoading: debitIsLoading,
    refetch,
  } = useGetDebtListQuery(shop);

  const [debtCustomerList, setDebtCustomerList] = useState([]);

  const columns = !debitIsLoading
    ? [
        { field: "No", headerName: "No.", width: 50 },
        {
          field: "name",
          headerName: "Name",
          flex: 1,
        },
        {
          field: "phone",
          headerName: "Phone",
          flex: 1,
        },
        {
          field: "bill",
          headerName: "Bill Amount",
          flex: 1,
        },
        {
          field: "vouchers",
          headerName: "Voucher List",
          flex: 1,
        },
        {
          field: "action",
          headerName: "Paid Debt",
          flex: 1,
          renderCell: (params) => (
            <div className="flex flex-row gap-4 justify-center items-center w-full">
              {/* <div> */}
              <div>
                <MyToolTip style={`bg-green-500`} content={"Paid Money"}>
                  <IconButton
                    onClick={() => openDrawer(params.row)}
                    className="w-6 h-6 bg-green-200 p-4"
                  >
                    <FaMoneyCheck className="h-4 w-4 text-green-600 font-extrabold" />
                  </IconButton>
                </MyToolTip>
              </div>
              <div>
                <MyToolTip style={`bg-amber-500`} content={"Voucher List"}>
                  <IconButton
                    onClick={() => showDebitDetails(params.row)}
                    className="w-6 h-6 bg-amber-200 p-4"
                  >
                    <MdPreview className="h-4 w-4 text-amber-600 font-extrabold" />
                  </IconButton>
                </MyToolTip>
              </div>
            </div>
          ),
        },
      ]
    : [];

  const openDrawer = (e) => {
    const paid = {
      customer_id: e.customer.id,
      shop_id: shop,
      old_credit: e.customer.note,
    };
    setBill(paid);
    setOpen(true);
  };

  const showDebitDetails = (row) => {
    setShowDetails(row.voucherList);
    setOpenList(true);
  };

  useEffect(() => {
    if (isSuccess) {
      setDebtCustomerList([
        ...data?.data.map((debt, i) => ({
          ...debt,
          No: i + 1,
          id: i + 1,
          name: debt.customer.name,
          phone: debt.customer.phone,
          bill: debt.customer.note,
        })),
      ]);
    }
  }, [isSuccess, data]);

  return (
    <div className="flex flex-col w-full h-full select-none">
      <div className="flex-row w-full justify-start flex my-2">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
          Remain Debt
          </p>
      </div>

      <div className="flex flex-col w-full h-full">
        <Box sx={{ height: 600, width: "100%" }}>
          {!debitIsLoading ? (
            <DataGrid
              rows={debtCustomerList}
              loading={debitIsLoading}
              density="compact"
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              onRowDoubleClick={(params) => showDebitDetails(params.row)}
              pageSizeOptions={[5,10,25,50,100]}
              disableRowSelectionOnClick
              slots={{ toolbar: GridToolbar }}
              showCellVerticalBorder
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              //loading
            />
          ) : (
            <div className="flex flex-row w-full h-full items-center justify-center">
              <Spinner />
            </div>
          )}
        </Box>
      </div>
      <DebitDetailDialog
        open={open}
        handleClose={() => setOpen(false)}
        updateBill={bill}
        refetch={refetch}
      />
      <CustomerDebitVoucherList
        open={openList}
        handleClose={() => setOpenList(false)}
        showDetails={showDetails}
      />
    </div>
  );
};

export default DebtPaidPage;
