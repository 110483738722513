import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import PaidRoundedIcon from "@mui/icons-material/PaidRounded";
import { useUpdateDebtPaymentMutation } from "../ApiService/SaleVoucherApiSlice";
import { Spinner } from "@material-tailwind/react";
import { useGetMontlyReportsQuery } from "../ApiService/MontlyReportsApiSlice";

const DebitDetailDialog = ({ open, handleClose, updateBill, refetch}) => {
  const [amount, setAmount] = useState(0);
  const shop = localStorage.getItem("shop_id");
  const [updatePayment, result] = useUpdateDebtPaymentMutation();
  const {refetch:monthlyRefetch } = useGetMontlyReportsQuery(shop);


  const DebtPayment = async (detail) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const new_credit = detail.old_credit - amount;
    const body = {
      id: detail.id,
      user_id: user.id,
      customer_id : detail.customer_id,
      shop_id : detail.shop_id,
      old_credit : detail.old_credit,
      new_credit:new_credit,
      total_credit:new_credit,
      paid: amount,
    };
    await updatePayment(body);
    monthlyRefetch();
    refetch();
    handleClose();
  };

  useEffect(() => {
    if (result.isSuccess) {
      refetch();
    }
  }, [result]);
  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>
          <p className="font-semibold font-poppins text-center text-gray-600 p-4 rounded-xl">
            Debit Details
          </p>
          <p className="flex flex-row justify-between"><span>Remaining Amount of Debt</span> <span>{ updateBill.old_credit }</span></p>
        </DialogTitle>
        <DialogContent>
          {/* <div className="flex flex-row">
            <div className="flex-1 flex-col font-poppins overflow-y-auto scrollbar-hide items-center p-2">
              <div className="flex flex-row justify-between items-center my-2">
                <div className="text-gray-600"> Customer Name</div>
                <div className="mx-4 text-gray-700">
                  {showDetails.customer?.name}
                </div>
              </div>
              <div className="flex flex-row justify-between items-center my-2">
                <div className="text-gray-600"> Phone</div>
                <div className="mx-4 text-gray-700">
                  {showDetails.customer?.phone}
                </div>
              </div>
              <div className="flex flex-row justify-between items-center my-2">
                <div className="text-gray-600"> Address </div>
                <div className="mx-4 text-gray-700">
                  {showDetails.customer?.address}
                </div>
              </div>
              <div className="flex flex-row justify-between items-center my-2">
                <div className="text-gray-600"> Total Amount </div>
                <div className="mx-4 text-gray-700">{showDetails.total}</div>
              </div>
              <div className="flex flex-row justify-between items-center my-2">
                <div className="text-gray-600"> Bill Amount </div>
                <div className="mx-4 text-gray-700">{showDetails.bill}</div>
              </div>
              <div className="flex flex-row justify-between items-center my-2">
                <div className="text-gray-600"> Voucher ID </div>
                <div className="mx-4 text-gray-700">{showDetails.id}</div>
              </div>
            </div>
          </div> */}

          <div className="flex flex-row w-full justify-between items-center">
            <TextField
              size="small"
              autoFocus
              varient={"outlined"}
              label={""}
              placeholder="Paid Amount"
              type="number"
              onChange={(e) => {
                const regex = /^[0-9\b]+$/;
                if ((e.target.value <= updateBill.old_credit && e.target.value > 0) || regex.test(e.target.value)) {
                  setAmount(e.target.value);
                } else {
                  return;
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                }
              }}
            />
            <Button
              variant="contained"
              startIcon={<PaidRoundedIcon />}
              onClick={()=>DebtPayment(updateBill)}
            >
              {!result.isLoading ? <span>Pay Amount</span> : <Spinner/>}
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DebitDetailDialog;
