import { configureStore } from "@reduxjs/toolkit";
import { ApiSlice } from "../ApiService/ApiSlice";
import saleVoucherReducer from "../ApiService/DailySaleSlice";
import productSliceReducer from "../ApiService/ProductSlice";

export default configureStore({
    reducer: {
        [ApiSlice.reducerPath]: ApiSlice.reducer,
        saleDetails : saleVoucherReducer,
        productList : productSliceReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(ApiSlice.middleware),
})