import { ApiSlice } from "./ApiSlice";

export const DashboardApiSlice = ApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getExpense: builder.query({
            query: (id) => `/expense/${id}`,
            providesTags:['Expense']
        }),
        getPaidListByCustomer:builder.query({
            query: ({shop,customer}) => `/customer-paid-list/${shop}/${customer}`,
            providesTags: ['Expense'],
        }),
        getMonthExpense: builder.query({
            query: (id) => `/month-expense/${id}`,
            providesTags:['Expense']
        }),
        
        getExpensesByDate: builder.mutation({
            query: (body) => ({
                url: `/expense/${body.id}`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Expense'],
        }),
        addExpense: builder.mutation({
            query: (body) => ({
                url: '/add-expense',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Expense'],
        }),
        editExpense: builder.mutation({
            query: (body) => ({
                url: `/edit-expense/${body.id}` ,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Expense'],
        }),
        deleteExpense: builder.mutation({
            query: (id) => ({
                url: `/delete-expense/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Expense'],
        }),
    })
});

export const {
  useGetExpenseQuery,
  useAddExpenseMutation,
  useEditExpenseMutation,
  useDeleteExpenseMutation,
  useGetMonthExpenseQuery,
  useGetPaidListByCustomerQuery,
  useGetExpensesByDateMutation,
} = DashboardApiSlice