import { ApiSlice } from "./ApiSlice";

export const DashboardApiSlice = ApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getDashboard: builder.query({
            query: (id) => `/dashboard/${id}`,
            providesTags:['Dashboard']
        }),
    })
});

export const {
   useGetDashboardQuery,
} = DashboardApiSlice