import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "@material-tailwind/react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { IconButton, Box, Typography } from "@mui/material";
import { useGetDailyReportsQuery ,useGetDailyReportByDateMutation} from "../../ApiService/MontlyReportsApiSlice";
import dayjs from "dayjs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

const DailySaleReport = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const auth = localStorage.getItem("auth");
    if (!auth) {
      navigate("/login");
    }
  }, []);

  var today = new Date();
  const date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

  const [reportDay, setReportMonth] = useState([]);
  const shop = localStorage.getItem("shop_id");
  
  const [startDate, setStartDate] = useState(dayjs(date));
  const [endDate, setEndDate] = useState(dayjs(date));
  const [isSearch, setIsSearch] = useState(false);
  

  const {
    data: dailyReport,
    isLoading: reportLoading,
    isError,
    isSuccess,
    refetch,
  } = useGetDailyReportsQuery(shop);

  const [ SearchDailySaleByDate, result] = useGetDailyReportByDateMutation();
  

  const columns = !reportLoading
    ? [
        { field: "No", headerName: "No.", width: 50 },
        {
          field: "created_at",
          headerName: "Date",
          sortable: true,
          width:100,
          renderCell: (params) => {
            const index = (params.value.split("T")[0]);
            const y = parseInt(params.value.split("T")[1]);

            return <p className="">{index}</p>;
          },
        },
        {
          field: "sale_voucher_id",
          headerName: "Voucher No",
          width: 100,
        },
        {
          field: "product_name",
          headerName: "Product Name",
          flex: 1,
        },
        {
          field: "qty",
          headerName: "Qty",
         width:50,
        },
        {
          field: "cash",
          headerName: "Total Amount",
          flex: 1,
          renderCell: (params) => {
           
            return (
              <h1
                className={`font-poppins p-1 rounded-lg`}
                onClick={() => console.log(params.row)}
              >
                {Math.floor(parseInt(params.row.qty) * parseInt(params.row.sell_price))}
              </h1>
            );
          },
        },
        // {
        //   field: "total",
        //   headerName: "Total Amount",
        //   sortable: true,
        //   flex: 1,
        //   renderCell: (params) => {
            
        //     return (
        //       <h1
        //         className={`font-poppins p-1 rounded-lg  ${
        //          true
        //             ? "text-red-400 bg-red-100"
        //             : "text-green-800 bg-green-100"
        //         }`}
        //         onClick={() => console.log(params.row)}
        //       >
        //         {params.value}
        //       </h1>
        //     );
        //   },
        // },
      ]
    : [];

    const DailySearchByDate = async () => {
      const start = startDate.format("YYYY-MM-DD");
      const end = endDate.format("YYYY-MM-DD");

      const body = {
        shopid:shop,
        start:start,
        end:end,
      }
      await SearchDailySaleByDate(body);
      setIsSearch(true)
    };

  useEffect(() => {
    if (isSuccess) {
      console.log(dailyReport?.data);
      setReportMonth([
        ...dailyReport?.data.map((sale, i) => ({
          ...sale,
          No: i + 1,
          id: i + 1,
          product_name: sale.product.name,
        })),
      ]);
    }
  }, [isSuccess, dailyReport]);

  useEffect(() => {
    if (result.isSuccess) {
     console.log(result?.data.data);
      setReportMonth([
        ...result?.data.data.map((sale, i) => ({
          ...sale,
          No: i + 1,
          id: i + 1,
          product_name: sale.product.name,
        })),
      ]);
    } 
  }, [result]);

  return (
    <div className="flex flex-col w-full h-full select-none">
      <div className="flex-row w-full justify-between flex my-2 items-center">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
          Daily Sale Product
        </p>
        <div className="flex flex-row w-fit p-2 gap-4 items-center">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="YYYY-MM-DD"
                defaultValue={startDate}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                label="Choose Start Date"
                className="pr-2 w-full"
                slotProps={{ textField: { size: "small" } }}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="YYYY-MM-DD"
                defaultValue={endDate}
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                label="Choose End Date"
                className="pr-2 w-full"
                slotProps={{ textField: { size: "small" } }}
              />
            </LocalizationProvider>
            <IconButton
              aria-label="delete"
              size="large"
              onClick={DailySearchByDate}
            >
              <SearchOutlinedIcon />
            </IconButton>
        </div>
        
        {/* <div
          className="px-2 py-1 bg-green-500 rounded-lg text-center items-center text-white"
          onClick={() => window.location.reload()}
        >
          <span>Reload</span>
        </div> */}
      </div>
      { isSearch && (
            <div className="flex flex-row items-center font-poppins">
              <IconButton
                aria-label="delete"
                size="large"
                onClick={()=> setIsSearch(false)}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography>
                Search Results For Between {startDate.format("YYYY-MM-DD")}
                
              </Typography>
            </div>
          )}
      <Box sx={{ height: 600, width: "100%" }}>
          { isSuccess ? (
            <DataGrid
              rows={ reportDay }
              loading={reportLoading}
              density="compact"
              // getRowClassName={(params) => {
              //   return params.row.rem_qty < params.row.alert_qty
              //     ? "text-red-400"
              //     : "";
              // }}
              columns={columns}
              pageSizeOptions={[5,10,25,50,100]}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
            //   onRowDoubleClick={(params) => showMonthReportDetials(params.row)}
              disableRowSelectionOnClick
              slots={{ toolbar: GridToolbar }}
              showCellVerticalBorder
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              //loading
            />
          ) : (
            <div className="flex flex-row w-full h-full items-center justify-center">
              <Spinner />
            </div>
          )}
        </Box>
    </div>
  );
};

export default DailySaleReport;
