import React, { useEffect,useState } from 'react'
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { IconButton, Box, Typography } from "@mui/material";
import { Spinner } from "@material-tailwind/react";
import { useGetProfitLossQuery } from '../../ApiService/MontlyReportsApiSlice'

const DailyReports = () => {

  const shop = localStorage.getItem('shop_id');
  const [reportData,setReportData] = useState([])

  const { data, isSuccess, isLoading,refetch } = useGetProfitLossQuery(shop);

  const columns = !isLoading
  ? [
      { field: "id", headerName: "No.", width: 60 },
      {
        field: "days",
        headerName: "Date",
        sortable: true,
        width:100,
        renderCell:(params)=>(
          <p> { params.row.days}</p>
        )
      },
      {
        field: "open",
        headerName: "Opening",
        width: 150,
        renderCell: (params)=>(
          <p> { typeof params.row.expense !== 'undefined' && params.row.expense?.length > 0 ? params.row.expense[0].open_amount : "0"}</p>
        )
      },
      {
        field: "saleamount",
        headerName: "Sale Amount",
        width: 150,
      },
      {
        field: "billamount",
        headerName: "Bill Amount",
       width:150,
      },
      {
        field: "discountamount",
        headerName: "Discount Amount",
        width: 150,
      },
      {
        field: "income",
        headerName: "Income Amount",
        width: 150,
        renderCell: (params)=>(
          <p> {typeof params.row.expense !== 'undefined' && params.row.expense?.length > 0  ? params.row.expense[0].income_amount : "0"}</p>
        )
      },
      {
        field: "expense",
        headerName: "Expense Amount",
        width: 150,
        renderCell: (params)=>(
          <p> { typeof params.row.expense !== 'undefined' && params.row.expense.length > 0  ? params.row.expense[0].expense_amount : "0"}</p>
        )
      },
      {
        field: "transfer",
        headerName: "Transfer Amount",
        width: 150,
        renderCell: (params)=>(
          <p> { typeof params.row.expense !== 'undefined' && params.row.expense.length > 0  ?  params.row.expense[0].transfer_amount : "0"}</p>
        )
      },
      {
        field: "remain",
        headerName: "Remain Amount",
        width: 150,
        renderCell: (params)=>{
          const amt = params.row;
          const income = typeof params.row.expense !== 'undefined' && params.row.expense?.length > 0  ? parseInt(amt.expense[0].income_amount) : 0;
          const expense = typeof params.row.expense !== 'undefined' && params.row.expense?.length > 0  ? parseInt(amt.expense[0].expense_amount) : 0;
          const open = typeof params.row.expense !== 'undefined' && params.row.expense?.length > 0  ? parseInt(amt.expense[0].open_amount) : 0;
          const transfer = typeof params.row.expense !== 'undefined' && params.row.expense.length > 0  ? parseInt(amt.expense[0].transfer_amount) : 0;
          
          const remain = parseInt(amt.saleamount) + income +  open - expense -  transfer -  parseInt(amt.billamount) -  parseInt(amt.discountamount)
          return <div className="">
            { remain }
          </div>
        }
      
      },

    ]
  : [];

  useEffect(()=>{
    if(isSuccess){
      console.log(data)
      setReportData([...data.data.map((rep,i)=> ({ ...rep,id: i + 1}))])
    }

  },[data,isSuccess]);

  return (
    <div className="flex flex-col w-full h-full select-none">
      <div className="flex-row w-full justify-between flex my-2 items-center">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
          Daily Reports
        </p>
        
        {/* <div
          className="px-2 py-1 bg-green-500 rounded-lg text-center items-center text-white"
          onClick={() => window.location.reload()}
        >
          <span>Reload</span>
        </div> */}
      </div>

      <Box sx={{ height: 600, width: "100%" }}>
          { isSuccess ? (
            <DataGrid
              rows={ reportData }
              loading={isLoading}
              density="compact"
              // getRowClassName={(params) => {
              //   return params.row.rem_qty < params.row.alert_qty
              //     ? "text-red-400"
              //     : "";
              // }}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
            //   onRowDoubleClick={(params) => showMonthReportDetials(params.row)}
            pageSizeOptions={[5,10,25,50,100]}
              disableRowSelectionOnClick
              slots={{ toolbar: GridToolbar }}
              showCellVerticalBorder
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              //loading
            />
          ) : (
            <div className="flex flex-row w-full h-full items-center justify-center">
              <Spinner />
            </div>
          )}
        </Box>
    </div>
  )
}

export default DailyReports
