import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { v4 as uuidv4 } from "uuid";
import { TextField, Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  useGetProdcutByStoreQuery,
  useGetCheckInOutMutation,
} from "../../ApiService/ProductApiSlice";
import { Spinner } from "@material-tailwind/react";
import ProductTransfer from "../../Components/ProductTransfer";

const CheckProductInOut = () => {
  const shop = localStorage.getItem("shop_id");
  const {
    data: products,
    isLoading: productsLoading,
    isError: productsError,
    isSuccess,
    refetch,
  } = useGetProdcutByStoreQuery(shop);

  const [checkPd, results] = useGetCheckInOutMutation();

  const [ProductLst, setLstProduct] = useState([]);
  const [value, setValue] = useState(null);
  const [productIn, setProductIn] = useState([]);
  const [productOut, setProductOut] = useState([]);
  const [inCount, setIncount] = useState(0);
  const [outCount, setOutcount] = useState(0);
  const [inTotal,setInTotal] = useState(0);
  const [outTotal, setOutTotal] = useState(0);
  const [takeDetails,setTakeDetails] = useState([]);
  const [giveDetails,setGiveDetails] = useState([]);

  const chooseItem = async (val, e) => {
    if (val === undefined) return;
    console.log(val.id);

    const body = {
      shop_id: shop,
      product_id: val.id,
      saas: "futuretechmm",
    };
    await checkPd(body);
  };

  const [open,setOpen] = useState(false);

  const inColumn = [
    { field: "no", headerName: "No.", width: 60 },
    {
      field: "updated_at",
      headerName: "Date",
      sortable: true,
      width: 120,
      renderCell: (params) => {
        const index = params.value.split("T")[0];
        return <p className={`${params.row.status == 1 ? "text-red-300" : ""}`}>{index}</p>;
      },
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => {
        console.log(params.row.name)
        return <p className={`${params.row.status == 1 ? "text-red-300" : ""}`}>{params.row.name}</p>;
      },
    },
    {
      field: "qty",
      headerName: "Qty",
      sortable: true,
      width: 60,
      renderCell: (params) => {
        console.log(params)
        return <p className={`${params.row.status == 1 ? "text-red-300" : ""}`}>{params.row.qty}</p>;
      },
    },
    {
      field: "purchase_voucher_id",
      headerName: "Voucher ID",
      sortable: true,
      width: 120,
    },
  ];
  const outColumn = [
    { field: "no", headerName: "No.", width: 60 },
    {
      field: "updated_at",
      headerName: "Date",
      sortable: true,
      width: 120,
      renderCell: (params) => {
        const index = params.value.split("T")[0];
        const y = parseInt(params.value.split("T")[1]);

        return <p className="">{index}</p>;
      },
    },
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      flex: 1,
    },
    {
      field: "qty",
      headerName: "Qty",
      sortable: true,
      width: 60,
    },
    {
      field: "sale_voucher_id",
      headerName: "Sale Voucher ID",
      sortable: true,
      width: 120,
    },
  ];

  useEffect(() => {
    if (!productsLoading) {
      const lstProduct = products?.data.map((cus, id) => ({
        ...cus,
        label: cus.name,
        id: id + 1,
      }));

      setLstProduct([
        ...lstProduct?.map((p) => {
          return {
            ...p.product,
            label:
              p.product.name +
              " - (" +
              p.qty +
              " ) - " +
              Math.ceil(
                ((p.product.purchase.price +
                  (p.product.purchase.price * p.product.remark) / 100) *
                  p.product.currency.ChangePrice) /
                  100
              ) *
                100 +
              " Ks ",
          };
        }),
      ]);
    } else if (productsError) {
      navigator("/net-err");
    }
    // dispatch(getProductByVoucher({ id: state.voucher.id }));
  }, [products]);

  useEffect(() => {
    if (results.isSuccess) {
      setProductIn([
        ...results.data.transfer.map((t, i) => ({
          ...t,
          no: i + 1,
          name: t?.product.name,
        })),
      ]);
      // console.log(results.data.transfer);
      setProductOut([
        ...results.data.sale.map((s, i) => ({
          ...s,
          no: i + 1,
          name: s?.product.name,
        })),
      ]);
      setIncount(results.data.transfer_count);
      setOutcount(results.data.sale_count);

      setTakeDetails(results.data.take);
      setGiveDetails(results.data.give);

      setInTotal(results.data.transfer.reduce((a,v) =>  a = a + v.qty , 0 ))
      setOutTotal(results.data.sale.reduce((a,v) =>  a = a + v.qty , 0 ))
    }
  }, [results]);

  return (
    <div className="flex flex-col w-full h-full select-none">
      <div className="flex-row w-full justify-between flex my-2 items-center">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
          အဝင်စာရင်း - {inCount}
        </p>
        <div className="flex flex-row justify-center">
          <div className="border-[1px] rounded-md p-2 w-fit">
            {isSuccess ? (
              <Autocomplete
                key={uuidv4()}
                id="combo-box"
                autoHighlight
                selectOnFocus
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={ProductLst}
                // options={top100Films}
                sx={{ width: 400 }}
                onChange={(event, newValue) => {
                  // console.log(newValue)
                  if (newValue !== undefined) {
                    chooseItem(newValue, event);
                  }
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label="Item Name"
                      variant="outlined"
                      autoFocus
                      size="small"
                    />
                  );
                }}
              />
            ) : (
              <Spinner />
            )}
          </div>
        </div>
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
          အထွက်စာရင်း - { outCount}
        </p>
        <p className="px-4 py-2 bg-[#57cd47] rounded-lg text-white font-bold shadow-lg border-[1px] border-white cursor-pointer" onClick={()=> setOpen(true)}>
          Product Transfer
        </p>
      </div>

      <div className="flex flex-row w-full justify-between gap-2 mt-4">
        <div className="w-1/2 bg-green-50 h-[400px] rounded-md">
          <Box sx={{ height: "100%", width: "100%" }}>
            {isSuccess ? (
              <DataGrid
                rows={productIn}
                loading={results.isLoading}
                density="compact"
                columns={inColumn}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                //   onRowDoubleClick={(params) => showMonthReportDetials(params.row)}
                pageSizeOptions={[5, 10, 25, 50, 100]}
                disableRowSelectionOnClick
                slots={{ toolbar: GridToolbar }}
                showCellVerticalBorder
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
                //loading
              />
            ) : (
              <div className="flex flex-row w-full h-full items-center justify-center">
                <Spinner />
              </div>
            )}
            <div className="flex flex-row py-2 bg-blue-gray-400 my-1 rounded-lg px-14 justify-between items-center">
                <p className="text-white">အဝင်ပစ္စည်း အရေအတွက် စုစုပေါင်း</p>
            <p className="text-green-600 bg-green-50 px-2 py-1 rounded-md font-bold">{inTotal}</p>
            </div>
          </Box>
        </div>
        <div className="w-1/2 bg-yellow-50 h-[400px] rounded-md">
          <Box sx={{ height: "100%", width: "100%" }}>
            {isSuccess ? (
              <DataGrid
                rows={productOut}
                loading={results.isLoading}
                density="compact"
                columns={outColumn}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                //   onRowDoubleClick={(params) => showMonthReportDetials(params.row)}
                pageSizeOptions={[5, 10, 25, 50, 100]}
                disableRowSelectionOnClick
                slots={{ toolbar: GridToolbar }}
                showCellVerticalBorder
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
                //loading
              />
              
            ) : (
              <div className="flex flex-row w-full h-full items-center justify-center">
                <Spinner />
              </div>
            )}
            <div className="flex flex-row py-2 bg-blue-gray-400 my-1 rounded-lg px-14 justify-between items-center">
                <p className="text-white">အထွက်ပစ္စည်း အရေအတွက် စုစုပေါင်း</p>
            <p className="text-red-600 bg-red-50 px-2 py-1 rounded-md font-bold">{outTotal}</p>
            </div>
           
          </Box>
        </div>
      </div>

      <ProductTransfer open={open} handleClose={()=>setOpen(false)} giveDetails={giveDetails} takeDetails={takeDetails} />
    </div>
  );
};

export default CheckProductInOut;
