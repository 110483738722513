import React, { useRef } from "react";


import { Dialog, DialogTitle, DialogContent, TextField } from "@mui/material";

const QtyDialog = ({ open, handleClose, handlerUpdate }) => {
  const qtyRef = useRef(1);

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Enter Quantity</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="qty-dialog"
            label=""
            inputRef={qtyRef}
            type="number"
            placeholder="Enter Quantity"
            fullWidth
            variant="standard"
            onChange={(e) => {
              const regex = /^[0-9\b]+$/;
              if (e.target.value === "" || regex.test(e.target.value)) {
                
              } else {
                return;
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handlerUpdate(e.target.value);
                handleClose();
              }
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default QtyDialog;
