import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lstSaleVoucher: [],
  lstProduct:[],
  totalAmount: 0,
};

export const saleVoucherSlice = createSlice({
  name: "saleDetails",
  initialState,
  reducers: {
    addSaleVoucher: (state, action) => {
      !state.lstSaleVoucher.some(
        (voucher) => voucher.id === action.payload.id
      ) && state.lstSaleVoucher.push(action.payload);
    },

    addSaleProduct2Voucher: (state, action) => {

      state.lstSaleVoucher = state.lstSaleVoucher.map((voucher) => voucher.id === action.payload.id ? {...voucher, product: action.payload.product}: {...voucher});
      state.lstProduct = state.lstSaleVoucher.filter((product) => product.id === action.payload.id)
      state.totalAmount = (state.lstProduct.reduce((a,v) =>  a = a + v.total , 0 ))
    },

    updateSaleProduct: (state, action) => {
      const vId = state.lstSaleVoucher.find(
        (obj) => obj.id === action.voucherId
      );
    },

    getProductByVoucher:(state,action) => {
        state.lstProduct = state.lstSaleVoucher.filter((product) => product.id === action.payload.id)
    },

    checkOutVoucher:(state,action)=>{
      state.lstSaleVoucher = state.lstSaleVoucher.filter((voucher) => voucher.id !== action.payload.id);
    }
  },
});

export const {
  addSaleVoucher,
  addSaleProduct2Voucher,
  updateSaleProduct,
  getProductByVoucher,
  checkOutVoucher
} = saleVoucherSlice.actions;

export default saleVoucherSlice.reducer;
