import { ApiSlice } from "./ApiSlice";

export const DailySaleApiSlice = ApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getDailySale: builder.query({
            query: () => '/daily-sale',
            providesTags:['DailySales']
        }),

        addDailySale: builder.mutation({
            query: (body) => ({
                url: '/daily-sale',
                method: 'POST',
                body,
            }),
            invalidatesTags:['DailySales'],
        }),

        addDailySaleVoucher: builder.mutation({
            query: (body) => ({
                url: '/sale-voucher',
                method: 'POST',
                body,
            }),
            invalidatesTags:['SaleVoucher'],
        }),

        // editCustomer: builder.mutation({
        //     query: (body) =>({
        //         url: `/sale-voucher/${body.get("id")}`,
        //         method: 'POST',
        //         body
        //     }),
        
        // }),

        deleteDailySale: builder.mutation({
            query: (id) =>({
                url: `/sale-voucher/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags:['SaleVoucher'],
        }),
    })
});


export const {
    useGetDailySaleQuery,
    useAddDailySaleMutation,
    useAddDailySaleVoucherMutation,
    useDeleteDailySaleMutation
 } = DailySaleApiSlice