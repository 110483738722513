import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter, HashRouter } from "react-router-dom";
import { ThemeProvider } from "@material-tailwind/react";
import store from "./store";
import { Provider } from "react-redux";

// import i18next from "i18next";
// import { initReactI18next } from "react-i18next";
// import HttpApi from 'i18next-http-backend'
// import LanguageDetector from "i18next-browser-languagedetector"

// i18next.use(HttpApi).use(LanguageDetector).use(initReactI18next).init({
//   supportedLngs: ['en', 'mm'],
//   fallbackLng:'en',
//   debug:false,
//   detection:{
//     order:['path','cookie','localstorage'],
//     caches:['cookie']
//   },
//   backend:{
//     loadPath:'Localization/{{lang}}/translation.json'
//   }
// })


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <>
    <ThemeProvider>
      {/* <BrowserRouter> */}
        <HashRouter>
        <Provider store={store}>
          <App />
        </Provider>
        </HashRouter>
      {/* </BrowserRouter> */}
    </ThemeProvider>
  </>

  // {/* </React.StrictMode> */}
);
