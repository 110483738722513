import React, { useEffect, useRef, useState, forwardRef } from "react";
import { useReactToPrint } from "react-to-print";
import Logo from "../../images/ttmm.png";
import html2canvas from "html2canvas";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

const PrintComponent = forwardRef((props, ref) => {
  console.log(props);
  console.log("Testing");
  const [i, setI] = useState({});
  var today = new Date();
  const date =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1) +
    "-" +
    today.getDate() +
    "-" +
    today.getHours() +
    ":" +
    today.getMinutes() +
    ":" +
    today.getSeconds();

  const printDocument = () => {
    const input = document.getElementById("divToPrint");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      setI(imgData);
      return imgData;
    });
  };

  useEffect(() => {
    // console.log(printDocument());
  }, [i]);

  return (
    <>
      <div
        id="divToPrint"
        className="flex flex-col w-[303px] h-fit text-center"
        ref={ref}
      >
        <div className="flex flex-row w-full h-fit pt-2 justify-center items-center">
          <img src={Logo} alt="slip" width={150} height={50} />
        </div>

        <div className="flex flex-col w-full h-fit py-2 justify-center items-center">
          {/* <p className="font-bold font-poppins text-base">ပေါ်ပြူလာ</p> */}
          <p className="font-bold font-poppins text-xs px-8">
            စပါးရိတ်သိမ်းခြွေလှေ့စက် ၊ ထွန်စက် နှင့် အပိုပစ္စည်း အရောင်းဆိုင်
          </p>
          {/* <p className="font-bold font-poppins text-xs">
            ဆိုင်အမှတ် ပ-၂၄၅၊ နေပြည်တော်မြို့မဈေးမြောက်ဘက်တန်း၊
          </p> */}
          <p className="font-bold font-poppins text-xs">
            ၀၉-၂၂၃၀၄၃၅၊ ၀၉၇၇၃၈၄၅၃
          </p>
        </div>
        <div className="flex flex-row w-full justify-start items-start">
          <div className="flex-1 flex-col h-fit px-2 justify-start items-start">
            <p className="font-poppins text-xs text-start">
              <span className="text-xs pr-2">အမည်၊</span>
              {props.props.cashier}
            </p>
            <p className="font-poppins text-xs text-start">
              <span className="text-xs pr-2">နေရပ်၊</span>
              {props.props.adds}
            </p>
          </div>

          <div className="flex flex-col w-fit h-fit px-2 justify-end items-end">
            <p className="font-poppins text-xs">
              <span className="text-xs">ရက်စွဲ၊ </span>
              {today.getDate() +
                "-" +
                (today.getMonth() + 1) +
                "-" +
                today.getFullYear()}
            </p>
          </div>
        </div>

        <div className="flex flex-row w-full h-fit px-2 pt-4">
          <div className="flex-1 text-xs text-start">အမျိုးအမည်</div>
          <div className="w-8 text-xs">ဦးရေ</div>
          <div className="text-xs w-16">နှုန်း</div>
          <div className="text-xs w-20">သင့်ငွေ</div>
        </div>
        <div>
          --------------------------------------------------------------
        </div>

        <div className="flex flex-row w-full h-fit py-2 pr-2">
          <>
            <div className="flex-1 text-xs">
              မြင်းကျားနှစ်ကောင် (YMO) အင်ဂျင်တပ်ဂျပန်ဝက်အူတိုင်
            </div>
            <div className="w-8 text-xs">၅</div>
            <div className="text-xs w-16">၃၀၀၀၀၀</div>
            <div className="text-xs w-20">၁၅၀၀၀၀၀</div>
          </>
        </div>
        <div>-------------------------------------------------------------</div>
        <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-xs">
          <span>ကျသင့်ငွေ </span>၁၅၀၀၀၀၀
        </div>
        <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-xs">
          <span>လျော့ပေးငွေ </span>၅၀၀၀
        </div>
        <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-xs">
          <span>ပေးချေငွေ </span>၁၄၉၅၀၀၀
        </div>
        <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-xs">
          <span>စုစုပေါင်းငွေ </span>၁၄၉၅၀၀၀
        </div>
        <div className="w-full h-fit text-xs text-center mt-4">
          🤝 ဝယ်ယူအားပေးမှုကို အထူးကျေးဇူးတင်ရှိပါသည်။ 🤝
        </div>
        <div className="flex flex-row w-full py-2 px-2 justify-between font-poppins text-xs mb-10">
          <span>Print Time </span>
          {date}
        </div>
        <div>
          --------------------------
          <span className="transform rotate-270 font-bold">✂</span>
          ---------------------------------
        </div>
      </div>
    </>
  );
});

const PrintPage = (props) => {
  const componentRef = useRef();
  // const imgRef = useRef();
  const [printNow, setPrintNow] = useState(false);
  var today = new Date();
  const date =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1) +
    "-" +
    today.getDate() +
    "-" +
    today.getHours() +
    ":" +
    today.getMinutes() +
    ":" +
    today.getSeconds();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const paied = () => {
    setPrintNow(true);
  };

  // useEffect(() =>{

  // },[printNow]);

  return (
    <div>
      <div
        style={{ display: "none" }} // This make ComponentToPrint show   only while printing
      >
        <PrintComponent
          ref={componentRef}
          props={{
            cashier: "နိုင်ပန်းလှ ",
            adds: "မော်လမြိုင်မြို့မဈေး၊(C)ရုံ",
          }}
        />
      </div>
      {/* <img src={i} alt=""/> */}
      <button onMouseUp={paied} onMouseDown={handlePrint}>
        Print this out!
      </button>
      {/* <button onClick={printDocument} >Screen Shot</button> */}



      <div id="printDialog"></div>
    </div>
  );
};

export default PrintPage;
