
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Spinner,IconButton } from "@material-tailwind/react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useGetPaidListQuery } from "../../ApiService/SaleVoucherApiSlice";
import { MdRemoveRedEye } from "react-icons/md";
import { MyToolTip } from "../../Components";
import PaidListDetailsDialog from "../../Components/PaidListDetailsDialog";

const PaidListPage = () => {

    const [paidList,setPaidList] = useState([])
    const shop = localStorage.getItem('shop_id');
    const { data,isSuccess, isLoading:PaidListLoading } = useGetPaidListQuery(shop)
    const [body,setBody] = useState([])
    const [open,setOpen] = useState(false)
    
    const columns = !PaidListLoading
    ? [
        { field: "No", headerName: "No.", width: 50 },
        {
          field: "name",
          headerName: "Name",
          flex: 1,
        },
        {
          field: "phone",
          headerName: "Phone",
          flex: 1,
        },
        {
          field: "paid",
          headerName: "Bill Amount",
          flex: 1,
        },
        {
          field: "view",
          headerName: "View",
          sortable: true,
          flex: 1,
          renderCell: (params) => (
            <div>
            <MyToolTip style={`bg-amber-500`} content={"Voucher List"}>
              <IconButton
                onClick={() => showDebitDetails(params.row)}
                className="w-6 h-6 bg-blue-200 p-4"
              >
                <MdRemoveRedEye className="h-4 w-4 text-blue-600 font-extrabold" />
              </IconButton>
            </MyToolTip>
          </div>
          ),
        },
      ]
    : [];

    const showDebitDetails = async(row)=>{
      setBody({
        shop:shop,
        id:row.customer.id
      });
      setOpen(true);
    }

    useEffect(()=>{
        if(isSuccess){
            setPaidList([
                ...data?.data.map((debt,i)=> ({ ...debt, No: i + 1,id: i + 1,name: debt.customer?.name,phone: debt.customer?.phone, }))
            ])
        }
    },[isSuccess,data]); 

  return (
    <div className="flex flex-col w-full h-full select-none">
       <div className="flex-row w-full justify-start flex my-2">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
          Paid List
        </p>
      </div>
      <div className="flex flex-col w-full h-full">
      
      <Box sx={{ height: 600, width: "100%" }}>
        {!PaidListLoading ? (
          <DataGrid
            rows={ paidList }
            loading={PaidListLoading}
            getRowClassName={(params) => {
              return params.row.rem_qty < params.row.alert_qty
                ? "text-red-400"
                : "";
            }}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            // onRowDoubleClick={(params) => showDebitDetails(params.row)}
            pageSizeOptions={[5,10,25,50,100]}
            disableRowSelectionOnClick
            slots={{ toolbar: GridToolbar }}
            showCellVerticalBorder
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            //loading
          />
        ) : (
          <div className="flex flex-row w-full h-full items-center justify-center">
            <Spinner />
          </div>
        )}
      </Box>
    </div>
          <PaidListDetailsDialog open={open} handleClose={()=> setOpen(false)} body ={body}/>
    </div>
  )
}

export default PaidListPage
