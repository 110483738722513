import { ApiSlice } from "./ApiSlice";

export const CustomerApiSlice = ApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        editProudctCount: builder.mutation({
            query: (body) =>({
                url: `/product_name/${body.product_name}`,
                method: 'POST',
                body
            }),
            // invalidatesTags: ['Count'],
        }),
    })
});

export const {
  useEditProudctCountMutation
} = CustomerApiSlice