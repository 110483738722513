import React,{ useEffect,useState } from "react";
import {
  IoStorefrontOutline,
  IoPersonCircleSharp,
  IoMedalOutline,
  IoLockOpenOutline,
  IoSyncSharp,
  IoLocationOutline
} from "react-icons/io5";

import { BiPhoneCall } from "react-icons/bi";
import { useGetDashboardQuery } from "../../ApiService/DashboardApiSlice";
import { useGetMontlyReportsQuery } from "../../ApiService/MontlyReportsApiSlice";
import { useGetProdcutByStoreQuery } from "../../ApiService/ProductApiSlice";
import { useGetMonthExpenseQuery } from '../../ApiService/ExpenseApiSlice';
import { useGetRefundRecordQuery } from "../../ApiService/ProductApiSlice";
import { Spinner } from "@material-tailwind/react";

const SettingPage = () => {

  // const [ updateShop ,result] = useUpdateShopInfoMutation();

  const user =JSON.parse(localStorage.getItem('user'));
  const shop = JSON.parse(localStorage.getItem('shopdetails'));

  const shopid = localStorage.getItem("shop_id")

  const [loading, setLoading] = useState(true);

  const { refetch } = useGetDashboardQuery(shopid);
  const { refetch:chartRef } = useGetMontlyReportsQuery(shopid);
  const { refetch:product } = useGetProdcutByStoreQuery(shopid);
  const { refetch:monthly } = useGetMontlyReportsQuery(shopid);
  const { refetch:monthlyExpense } = useGetMonthExpenseQuery(shopid);
  const { isSuccess,refetch:refund } = useGetRefundRecordQuery(shopid); 

  const reloadAllData = async()=>{
    setLoading(true);
    await refetch();
    await chartRef();
    await product();
    await monthlyExpense();
    await monthly()
    await refund();
    setLoading(false);
  }

  useEffect(()=>{

    if(isSuccess) {
      setLoading(false);
    }
    
  },[isSuccess]);

  return (
    <div className="flex flex-row w-full h-full select-none">
      {/* <Drawer placement="right" open={openDrawer} onClose={()=> setOpenDrawer(false)}>
        <div className="mb-2 flex items-center justify-between p-4">
          <Typography variant="h5" color="blue-gray">
            Update ShopInfo 
          </Typography>
          <IconButton variant="text" color="blue-gray" onClick={()=> setOpenDrawer(false)}>
            <XMarkIcon strokeWidth={2} className="h-5 w-5" />
          </IconButton>
        </div>
        <form className="flex flex-col gap-6 p-4">
          <Input
            type="text"
            label="Shop Name"
            defaultValue={open ? shop.name : ""}
            inputRef={shopName}
          />
          <Input
            type="text"
            label="Phone"
            defaultValue={open ? shop.phone : ""}
            inputRef={shopPhone}
          />
          <Input
            type="text"
            label="Address"
            defaultValue={open ? shop.address : ""}
            inputRef={shopAddress}
          />
          <Button onClick={updateShpInfo} className=" items-center">
            {result.isLoading ? <Spinner /> : "Save"}
          </Button>
        </form>
      </Drawer> */}
      {
        loading ? 
        <div className="mx-auto my-auto flex flex-col justify-center items-center">
          <p className="font-poppins text-lg text-gray-400 my-4 animate-bounce">Please Wait, All Data is now refreshing.... </p>
          <Spinner className="h-12 w-12"  color="teal"/>
        </div>
        
        :
        
        <div className=" relative flex flex-col mx-auto px-20 py-16 bg-white shadow-xl w-full md:w-1/2 font-poppins rounded-lg shadow-blue-gray-300 gap-6 justify-center">
      <div className="flex flex-row justify-between items-center m-2">
          <div className="flex flex-row items-center gap-4 font-semibold text-gray-600">
            <IoLockOpenOutline size={30} />
            <span>Reload Data</span>
          </div>

          <div className="flex flex-row items-center gap-4 font-semibold text-gray-600 hover:bg-white bg-gray-400 p-0.5 rounded-lg" onClick={reloadAllData}>
            <IoSyncSharp size={30} className="cursor-pointer hover:p-0.5 hover:rounded-lg hover:bg-gray-600 hover:text-white"/>
          </div>
        </div>
        <div className="flex flex-row justify-between items-center m-2">
          <div className="flex flex-row items-center gap-4 font-semibold text-gray-600">
            <IoStorefrontOutline size={30} />
            <span>Shop Name</span>
          </div>

          <p>{shop.name}</p>
        </div>
        <div className="flex flex-row justify-between items-center m-2">
          <div className="flex flex-row items-center gap-4 font-semibold text-gray-600">
            <BiPhoneCall size={30} />
            <span>Shop Phone Number</span>
          </div>

          <p>{ shop.phone } </p>
        </div>
        <div className="flex flex-row justify-between items-center m-2">
          <div className="flex flex-row items-center gap-4 font-semibold text-gray-600">
            <IoLocationOutline size={30} />
            <span>Shop Address </span>
          </div>

          <p>{ shop.address } </p>
        </div>
        <div className="flex flex-row justify-between items-center m-2">
          <div className="flex flex-row items-center gap-4 font-semibold text-gray-600">
            <IoPersonCircleSharp size={30} />
            <span>User Name</span>
          </div>

          <p>{ user.name} </p>
        </div>
        <div className="flex flex-row justify-between items-center m-2">
          <div className="flex flex-row items-center gap-4 font-semibold text-gray-600">
            <IoMedalOutline size={30} />
            <span>Account Type</span>
          </div>

          <p>Staff</p>
        </div>
        
      </div>
      
      }
    
  
    </div>
  );
};

export default SettingPage;
