import { ApiSlice } from "./ApiSlice";

export const DashboardApiSlice = ApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getMontlyReports: builder.query({
            query: (id) => `/month-report/${id}`,
            providesTags:['MonthReport']
        }),
        getDailyReports: builder.query({
            query: (shop) => `/daily-report/${shop}`,
            providesTags:['DailyReport']
        }),
        getDailyReportByDate: builder.mutation({
            query: (body) =>({
                url:  `/daily-report-date`,
                method: 'POST',
                body
            }),
            invalidatesTags:['DailyReport']
        }),
        getProfitLoss: builder.query({
            query: (id) => `/daily-profit-loss/${id}`,
        }),
        getVIPCustomers: builder.query({
            query: (id) => `/vip-customer/${id}`,
        }),
        getVIPVoucherList: builder.mutation({
            query: (body) =>({
                url:  `/vip-voucherlist`,
                method: 'POST',
                body
            }),
        }),

    })
});

export const {
  useGetMontlyReportsQuery,
  useGetDailyReportsQuery,
  useGetDailyReportByDateMutation,
  useGetProfitLossQuery,
  useGetVIPCustomersQuery,
  useGetVIPVoucherListMutation,
} = DashboardApiSlice

