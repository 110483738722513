import React, { useRef, forwardRef, useState } from "react";
import Logo from "../../images/ttmm.png";
import waterMark from "../../images/ttmmLogo.png";
import { useReactToPrint } from "react-to-print";
import title from "../../images/ttTitle.png";
import ttMap from "../../images/ttMap.png";

const MainStreamPrinter = () => {
  const componentRef = useRef();
  const [printNow, setPrintNow] = useState(false);

  const lstSales = [
    {
      product_name:
        " Black Friday Sales Return to Series Black Friday Sales Return to Series",
      qty: 4,
      sell_price: 4000,
      total: 16000,
    },
  ];

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const paid = () => {
    setPrintNow(true);
  };
  return (
    <>
      <div
        style={{ display: "block" }} // This make ComponentToPrint show   only while printing
      >
        <PrintComponent
          ref={componentRef}
          voucher={{
            cashier: "Zaw Gyi",
            adds: "Taung oo",
            id: "12",
          }}
          items={lstSales}
          summary={{
            total: "3300000",
            bill: "3332340",
            oldBill: "0",
            discount: "0",
            cash: "3300000",
          }}
        />
      </div>
      <button
        onMouseUp={() => paid()}
        onMouseDown={handlePrint}
        className="py-2 px-4 rounded-lg bg-green-500"
      >
        Print this MainStream Printer!
      </button>
    </>
  );
};

const PrintComponent = forwardRef((props, ref) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const { voucher, items, summary } = props;
  // console.log(props);
  // console.log("Testing");
  // const [i, setI] = useState({});
  var today = new Date();
  const date =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1) +
    "-" +
    today.getDate() +
    "-" +
    today.getHours() +
    ":" +
    today.getMinutes() +
    ":" +
    today.getSeconds();

  const shop = JSON.parse(localStorage.getItem("shopdetails"));

  function currencyFormat(num) {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  const phoneList = shop.phone.split(",")

  return (
    <>
      <div
        id="divToPrint"
        className="flex flex-col h-fit text-center px-4 relative"
        ref={ref}
      >
        <div className="flex flex-col w-full h-fit py-2 justify-center items-center">
          <div className="flex flex-row justify-center items-center">
            {/* <img
              src={ttMap}
              alt="logo"
              width={80}
              height={80}
              className=""
            /> */}
            <div>
              <div className="flex flex-row w-full h-fit justify-center items-center">
                <img src={Logo} alt="slip" width={"65%"} height={"auto"} />
              </div>
              <p className="font-bold font-poppins text-2xl py-1 text-balance">
                စပါးရိတ်သိမ်းခြွေလှေ့စက် ၊ ထွန်စက် နှင့် အပိုပစ္စည်း အရောင်းဆိုင်
              </p>
              
              <div className="grid grid-cols-5 gap-1 my-2">
                {
                phoneList.map((phone) => (
                  <p className="font-bold font-poppins text-sm text-balance w-full text-center justify-center items-center mx-auto">{phone}</p>
                ))
              }
              </div>
              
              <p className="font-bold font-poppins text-2xl pt-1">
                {shop.address}
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-row w-full h-fit justify-between items-start">
          <div className="flex flex-col justify-between items-start w-1/3">
            <p className="font-poppins text-sm flex flex-row w-full  justify-between items-center">
              <span className="text-sm pr-2">Voucher ID</span>
              <p>{voucher.id}</p>
            </p>
            <p className="font-poppins text-sm flex flex-row w-full justify-between items-center">
              <span className="text-sm pr-2">Cashier - </span>
              {user.name}
            </p>
            <p className="font-poppins text-sm flex flex-row w-full justify-between items-center">
              <span className="text-sm">Date - </span>
              <p>
                {today.getFullYear() +
                  "-" +
                  (today.getMonth() + 1) +
                  "-" +
                  today.getDate()}
              </p>
            </p>
          </div>

          <div className="flex flex-row justify-end grow-0">
            <div className="flex flex-col">
              <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
                <span className="text-sm pr-6 w-24 text-start">အမည်</span>
              </p>
              <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
                <span className="text-sm pr-6 w-24 text-start">လိပ်စာ</span>
              </p>
              <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
                <span className="text-sm pr-6 w-24 text-start">
                  ဖုန်းနံပါတ်
                </span>
              </p>
            </div>
            <div className="flex-1 flex-col justify-between">
              <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
                {voucher.cashier}
              </p>
              <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
                {voucher.adds}
              </p>
              <p className="font-poppins text-sm flex flex-row w-full justify-end items-center">
                {voucher.adds}
              </p>
            </div>
          </div>
        </div>




        <div className="w-full mt-4">
          <table className="w-full text-sm text-left text-black border border-black border-collapse py-5">
            <thead className="text-xs text-center text-white uppercase bg-blue-400 dark:text-white">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 border border-black border-collapse"
                >
                  No.
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 border border-black border-collapse"
                >
                  Product Name
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 border border-black border-collapse"
                >
                  Price
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 border border-black border-collapse"
                >
                  Qty
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 border border-black border-collapse"
                >
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr className="border border-black border-collapse">
                  <td
                    scope="row"
                    className="px-6 py-1 border border-black border-collapse text-center"
                  >
                    {index + 1}
                  </td>
                  <td className="px-2 py-1 border border-black border-collapse">
                    {item.product_name}
                  </td>
                  <td className="px-2 py-1 border border-black border-collapse text-right">
                    {currencyFormat(parseInt(item.sell_price))}
                  </td>
                  <td className="px-2 py-1 border border-black border-collapse text-center">
                    {item.qty}
                  </td>
                  <td className="px-2 py-1 border border-black border-collapse text-right">
                    {currencyFormat(parseInt(item.total))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className=" flex flex-row w-full justify-end text-base px-10">
          <div className=" flex flex-col w-2/3 justify-end items-end">
            <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base">
            <span className="text-start w-[60%]">
                စုစုပေါင်းငွေ{" "}
              </span>{" "}
              {summary.total}
            </div>
            <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base ">
            <span className="text-start w-[60%]">
                လျော့ပေးငွေ{" "}
              </span>
              {summary.discount}
            </div>
            <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base">
            <span className="text-start w-[60%]">ပေးချေငွေ </span>
              {summary.cash}
            </div>
            <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base">
            <span className="text-start w-[60%]">
                {" "}
                ယခင်ကြွေးကျန်{" "}
              </span>{" "}
              {summary.oldBill}
            </div>
            <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base ">
            <span className="text-start w-[60%]">
                {" "}
                ယခုကြွေးကျန်{" "}
              </span>
              {summary.bill}
            </div>
            <div className="flex flex-row w-full px-2 pr-2 justify-between font-poppins text-base ">
            <span className="text-start w-[60%]">
                စုစုပေါင်းကြွေးကျန်{" "}
              </span>{" "}
              {" - "}
            </div>
          </div>
        </div>

        <div className="w-full h-fit text-lg text-center mt-4 px-10">
          🤝 ဝယ်ယူအားပေးမှုကို အထူးကျေးဇူးတင်ရှိပါသည်။ 🤝
        </div>

        <div className="w-full h-fit text-lg text-center mt-4 px-10">
          ⚠️ ဝယ်ယူပြီး ပစ္စည်းပြန်မလဲပေးပါ။ ⚠️
        </div>

        <div className="flex flex-row w-full justify-between font-poppins text-base mb-10 px-10">
          <span>Print Time </span>
          {date}
        </div>
        <div>
          --------------------------
          <span className="transform rotate-270 font-bold">✂</span>
          ---------------------------------
        </div>
      </div>
    </>
  );
});

export default MainStreamPrinter;
