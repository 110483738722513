import React, { useEffect, useState } from "react";
import { Spinner } from "@material-tailwind/react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, IconButton } from "@mui/material";
import { FaEye } from "react-icons/fa";
import { MyToolTip } from "../../Components";
import {
  useGetVIPCustomersQuery,
  useGetVIPVoucherListMutation,
} from "../../ApiService/MontlyReportsApiSlice";
import VipCustomerReports from "../../Components/VipCustomerReports";

const VIPCustomerPage = () => {
  const shopId = localStorage.getItem("shop_id");
  const {
    data: vipCustomers,
    isSuccess,
    isLoading,
  } = useGetVIPCustomersQuery(shopId);
  const [voucherList, result] = useGetVIPVoucherListMutation();

  const [VipCustomer, setVipCustomer] = useState([]);
  const [printInfo, setPrintInfo] = useState({});
  const [details, setDetails] = useState([]);
  const [paidDebt, setPaidDebt] = useState([]);
  const [open, setOpen] = useState(false);
  const showDailySaleDetails = async (row) => {
    console.log(row);
    setPrintInfo({ ...row, customer: row.customer });
    let body = {
      shop: shopId,
      customer: row.customer_id,
    };
    await voucherList(body);
  };

  const columns = isSuccess
    ? [
        { field: "id", headerName: "No.", width: 50 },
        {
          field: "name",
          headerName: "Customer Name",
          flex: 1,
          renderCell: (params) => {
            return <div>{params.row.customer.name}</div>;
          },
        },
        {
          field: "vouchercount",
          headerName: "Voucher Counts",
          flex: 1,
        },
        {
          field: "saleamount",
          headerName: "Sales Amount",
          flex: 1,
        },
        {
          field: "billamount",
          headerName: "Bill Amount",
          flex: 1,
        },
        {
          field: "discountamount",
          headerName: "Discount Amount",
          sortable: true,
          flex: 1,
        },
        {
          field: "paiddebtamount",
          headerName: "Paid Debt Amount",
          sortable: true,
          flex: 1,
          renderCell: (params) => {
            return (
              <div className="text-green-600 bg-green-100 px-2 py-1 rounded-md font-bold">
                {params.row.billamount - params.row.customer.note}
              </div>
            );
          },
        },
        {
          field: "bill",
          headerName: "Remaining Bill",
          flex: 1,
          renderCell: (params) => {
            return (
              <div className="text-red-600 bg-red-100 px-2 py-1 rounded-md font-bold">
                {params.row.customer.note}
              </div>
            );
          },
        },
        {
          field: "action",
          headerName: "Action",
          sortable: true,
          flex: 1,
          renderCell: (params) => (
            <MyToolTip style={`bg-green-500`} content={"View"}>
              <IconButton onClick={() => showDailySaleDetails(params.row)}>
                {result.isLoading ? (
                  <Spinner />
                ) : (
                  <FaEye className="h-4 w-4 text-green-600 font-extrabold" />
                )}
              </IconButton>
            </MyToolTip>
          ),
        },
      ]
    : [];

  useEffect(() => {
    if (!isLoading) {
      setVipCustomer([
        ...vipCustomers?.data?.map((vip, i) => ({
          ...vip,
          id: i + 1,
          name: vip.customer.name,
        })),
      ]);
    }
  }, [vipCustomers, isLoading]);

  useEffect(() => {
    if (result?.isSuccess) {
      setDetails(result?.data.data?.voucherList?.slice().reverse());
      setPaidDebt(result?.data.data?.paid);
      setOpen(true);
    }
  }, [result]);

  return (
    <div className="flex flex-col w-full h-full select-none">
      <div className="flex-row w-full justify-start flex my-2">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
          VIP Customers Services
        </p>
      </div>
      <Box sx={{ height: 600, width: "100%" }}>
        <DataGrid
          rows={VipCustomer}
          loading={isLoading}
          getRowClassName={(params) => {
            return params.row.rem_qty < params.row.alert_qty
              ? "text-red-400"
              : "";
          }}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          onRowDoubleClick={(params) => showDailySaleDetails(params.row)}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          density="compact"
          disableRowSelectionOnClick
          slots={{ toolbar: GridToolbar }}
          showCellVerticalBorder
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          //loading
        />
      </Box>
      <VipCustomerReports
        open={open}
        handleClose={() => setOpen(false)}
        showDetails={details}
        print={printInfo}
        paid={paidDebt}
      />
    </div>
  );
};

export default VIPCustomerPage;
