import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Autocomplete from "@mui/material/Autocomplete";
import dayjs from "dayjs";

const MyDialog = ({ open, handleClose, handlerVoucher, customerlist }) => {
  var today = new Date();
  const date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  const [customer, setCustomer] = useState({});
  const [bill, setBill] = useState({});
  const [saleDate, setSalesDate] = useState(dayjs(date));
  const refDiscount = useRef("0");

  const setItem = () => {
    handleClose();
    if(customer.id == null) {
        return;
    }
    const voucherItem = {
      customer: customer,
      bill: customer.note,
      date: saleDate.format("YYYY-MM-DD"),
      discount: 0,
      product: [],
    };
    handlerVoucher(voucherItem);
  };

  const handlerCustomerDetails = (val, e) => {
    if (val === null) {
      setBill(0);
      return;
    }
    setCustomer(val);
    setBill(val.note);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Enter Voucher</DialogTitle>
        <DialogContent>
          <div className="w-full py-2">
            <Autocomplete
              disablePortal
              id="combo-box"
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={customerlist}
              onChange={(event, newValue) =>
                handlerCustomerDetails(newValue, event)
              }
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label="Choose Customer"
                    variant="outlined"
                    size="small"
                  />
                );
              }}
              className="w-full"
            />
          </div>

          {bill > 0 ? (
            <div className="w-full mb-2">
              <TextField
                size="small"
                autoFocus
                margin="dense"
                id="bill_amount"
                label="Remain Bill Amount"
                type="number"
                fullWidth
                variant="outlined"
                color="warning"
                value={bill}
                disabled
              />
            </div>
          ) : (
            <></>
          )}

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="YYYY-MM-DD"
              defaultValue={saleDate}
              selected={saleDate}
              onChange={(date) => setSalesDate(date)}
              label="Choose Date"
              className="pr-2 w-full my-4"
              slotProps={{ textField: { size: "small" } }}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={setItem}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MyDialog;
