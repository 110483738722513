import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = `https://warehouse.futuretechmm.net/pos-vendor-backend/public/api/`;

// const baseUrl = `http://localhost:8000/api/`;

// const baseUrl = `https://dev.futuretechmm.net/backend/public/api/`;
// const header = {
//     'Authorization': auth1 == null ? "" : `Bearer`,
//     'Accept': '*/*',
//     'Content-Type': 'application/json',
//     'enctype': 'multipart/form-data',
// }

// const createRequest = (url) => ({ url, headers: header });

export const ApiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            // console.log(auth)
            headers.set("Access-Control-Allow-Origin" , "*");
            headers.set("Content-Type",'application/json,charset=UTF-8');
            headers.set('Accept', `Application/json`);
            const token = (window.localStorage.getItem("token"));
            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                if (token) {
                    headers.set('authorization', `Bearer ${token}`)
                }
            }
           
            return headers
        },
    }),
    tagTypes: ['Product', 'Customer','SaleVoucher','Debit','Dashboard','DailySales','Expense'],
    endpoints: (build) => ({
 
    })
});