import React, { useEffect, useState } from "react";
import { DashboardCard, StackChart } from "../../Components";
import { Card } from "../../Constant";
import { useGetDashboardQuery } from "../../ApiService/DashboardApiSlice";
import { useGetMontlyReportsQuery } from "../../ApiService/MontlyReportsApiSlice";
import { useLocation, useNavigate } from "react-router-dom";

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const authKey = localStorage.getItem("auth");

  useEffect(() => {
    if(!authKey) {
      navigate("/login");
      // console.log(authKey);
      return;
    }
  },[]);

  const shop = localStorage.getItem("shop_id")

  const { data, isLoading, isSuccess, refetch, isError, error  } = useGetDashboardQuery(
    localStorage.getItem("shop_id")
  );
  const { data:montlyReport, isLoading:reportLoading, isSuccess:chartSuccess,refetch:chartRef } = useGetMontlyReportsQuery(shop);
  const [card, setCard] = useState(Card);

 

  useEffect(() => {
    
    if (isSuccess) {
      const newCard = Card;
      Object.keys(data).forEach(function (key, index) {
        newCard[index].count = data[key];
      });
      setCard([...newCard]);
    }else if (isError) {
        // console.log(error.errors);
    }
  }, [isSuccess]);

  useEffect(() => {
    refetch();
    // chartRef();
  }, [location]);


  return (
    <>
      <div className="flex flex-col w-full">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {card.map((item) => (
            <DashboardCard data={item} key={item.name} isLoading={isLoading}/>
          ))}
        </div>
        {/* <div className="flex-1 flex-col bg-purple-500 min-h-[400px]">
          Chart Area
        </div> */}
        <StackChart report={montlyReport?.data}/>
      </div>
    </>
  );
};

export default Dashboard;
