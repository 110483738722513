
import React, { useEffect, useState } from "react";
import { IconButton, Box,Typography } from "@mui/material";
import { MyToolTip } from "../../Components";
import { Spinner } from "@material-tailwind/react";
import { FaEye } from "react-icons/fa";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import SaleRecordDetails from "../../Components/SaleRecordDetails";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useGetRefundRecordQuery,useGetRefundRecordByDateMutation } from "../../ApiService/ProductApiSlice";
const SaleReturnList = () => {

  const shop = localStorage.getItem("shop_id");
  const { data, isSuccess, isLoading } = useGetRefundRecordQuery(shop); 
  const [ refundByDate, result] = useGetRefundRecordByDateMutation();

  var today = new Date();
  const date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

  const [ refundRecord, setRefundRecord] = useState([]);
  const [open,setOpen] = useState(false);
  const [details,setDetails] = useState([]);
  const [dateRefundRecord, setDateRefundRecord] = useState([]);

  const [startDate, setStartDate] = useState(dayjs(date));
  const [endDate, setEndDate] = useState(dayjs(date));
  const [isSearch, setIsSearch] = useState(false);


  useEffect(() => {
    if (isSuccess) {
      setRefundRecord(
        data?.data.map((sale, i) => ({ ...sale, id: i + 1 })),
      );
    }
  },[data,isSuccess]);


 const header = [
  { field: "No", headerName: "No.", width: 50 },
        {
          field: "id",
          headerName: "No",
          width: 70,
        },
        {
          field: "days",
          headerName: "Date",
          width:200,
        },
        {
          field: "total",
          headerName: "Total Amount",
          flex: 1,
        },
        {
          field: "itemType",
          headerName: "Item Type",
          flex: 1,
        },
        {
          field: "qty",
          headerName: "Item Qty",
          sortable: true,
          flex: 1,
        },
        {
          field: "action",
          headerName: "View Details",
          sortable: true,
          flex: 1,
          renderCell: (params) => <MyToolTip style={`bg-green-500`} content={"View"}>
          <IconButton
            onClick={()=>showDetailRecordDetails(params.row)}
          >
            <FaEye className="h-4 w-4 text-green-600 font-extrabold" />
          </IconButton>
        </MyToolTip>,
        },
 ];

 const showDetailRecordDetails = (row)=>{
    setDetails(row.refund);
    setOpen(true);
 }

 const SaleReturnDate = async() => {
    
    let body = {
      start: startDate,
      end: endDate,
      shop:shop,
    }
    await refundByDate(body);
    setIsSearch(true);
 }

 useEffect(()=>{
    if(result.isSuccess) {
      setDateRefundRecord(result?.data?.data.map((sale, i) => ({ ...sale, id: i + 1 })),)
    }
 },[result]);

  return (
    <div className="flex flex-col max-h-full">
      <div className="flex-row w-full justify-between flex">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold mb-4">
          Sale Return Record
        </p>
        <div className="flex flex-row w-fit p-2 gap-4 items-center">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="YYYY-MM-DD"
                defaultValue={startDate}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                label="Choose Start Date"
                className="pr-2 w-full"
                slotProps={{ textField: { size: "small" } }}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="YYYY-MM-DD"
                defaultValue={endDate}
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                label="Choose End Date"
                className="pr-2 w-full"
                slotProps={{ textField: { size: "small" } }}
              />
            </LocalizationProvider>
            <IconButton
              aria-label="delete"
              size="large"
              onClick={SaleReturnDate}
            >
              <SearchOutlinedIcon />
            </IconButton>
        </div>
      </div>
      { isSearch && (
            <div className="flex flex-row items-center font-poppins">
              <IconButton
                aria-label="delete"
                size="large"
                onClick={()=> setIsSearch(false)}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography>
                Search Results For Between {startDate.format("YYYY-MM-DD")}
                
              </Typography>
            </div>
          )}
      <div className="flex flex-col w-full h-full">
          <Box sx={{ height: 600, width: "100%" }}>
            {!isLoading ? (
              <DataGrid
                rows={ isSearch ? dateRefundRecord : refundRecord }
                loading={isSearch ? result.isLoading : isLoading}
                getRowClassName={(params) => {
                  return params.row.rem_qty < params.row.alert_qty
                    ? "text-red-400"
                    : "";
                }}
                columns={header}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                //onRowDoubleClick={(params) => showDailySaleDetails(params.row)}
                pageSizeOptions={[5,10,25,50,100]}
                disableRowSelectionOnClick
                slots={{ toolbar: GridToolbar }}
                showCellVerticalBorder
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
                //loading
              />
            ) : (
              <div className="flex flex-row w-full h-full items-center justify-center">
                <Spinner />
              </div>
            )}
          </Box>
          <SaleRecordDetails open={open} handleClose={()=>setOpen(false)} showDetails= {details}/>
        </div>
    </div>
  )
}

export default SaleReturnList
