import "./App.css";
import Layout from "./layouts/layout";
import { Routes, Route } from "react-router-dom";
import SellingPage from "./pages/SellingPage";
import Dashboard from "./pages/Dashboard/Dashboard";
import AddCustomer from "./pages/CustomerView/AddCustomer";
import DailySale from "./pages/DailySale/DailySale";
import DataGridTest from "./pages/Test/DataGridTest";
import RegisterPage from "./pages/AuthPage/RegisterPage";
import TImeOutConnection from "./pages/Connection/TImeOutConnection";
import ProductList from "./pages/Product/ProductList";
import SettingPage from "./pages/Setting/SettingPage";
import DebitPage from "./pages/Debit/DebitPage";
import SaleReportsPage from "./pages/SaleReports/SaleReportsPage";
import ShopProfit from "./pages/Profit/ShopProfit";
import LoginPage from "./pages/AuthPage/LoginPage";
import ExpensesList from "./pages/Expenses/ExpensesList";
import ExpensesAdd from "./pages/Expenses/ExpensesAdd";
import RefundPage from "./pages/Refund/RefundPage";
import PaidListPage from "./pages/Debit/PaidListPage";
import DebtPaidPage from "./pages/Debit/DebtPaidPage";
import DailySaleReport from "./pages/DailySaleReport/DailySaleReport";
import SaleReturnItem from "./pages/SaleReturn/SaleReturnItem";
import SaleReturnList from "./pages/SaleReturn/SaleReturnList";
import DailyReports from "./pages/DailyProfitLoss/DailyReports";
import MainStreamPrinter from "./pages/PrintPage/MainStreamPrinter";
import VIPCustomerPage from "./pages/VIPCustomer/VIPCustomerPage";
import VipCustomerHistory from "./pages/VIPCustomer/VipCustomerHistory";
import StockAdjustment from "./pages/StockAdjust/StockAdjustment";
import CheckProductInOut from "./pages/CheckProductInOut/CheckProductInOut";

// import { useTranslation } from "react-i18next";
// import cookies from 'js-cookie'
// import i18next from "i18next";

// const languages = [
//   {
//     code: 'mm',
//     name: 'Myanmar',
//     country_code: 'mm',
//   },
//   {
//     code: 'en',
//     name: 'English',
//     country_code: 'gb',
//   },
// ]

function App() {

  // const currentLanguageCode = cookies.get('i18next') || 'en'
  // const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  // const { t } = useTranslation()

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<RegisterPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/net-err" element={<TImeOutConnection />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/selling" element={<SellingPage />} />
        <Route path="/product" element={<ProductList />} />
        <Route path="/account" element={<SettingPage />} />
        <Route path="/debit" element={<DebitPage />} />
        <Route path="/paid" element={<PaidListPage />} />
        <Route path="/debt-paid" element={<DebtPaidPage />} />
        <Route path="/sale-reports" element={<SaleReportsPage />} />
        <Route path="/customer" element={<AddCustomer />} />
        <Route path="/daily-sales" element={<DailySale />} />
        <Route path="/test" element={<DataGridTest />} />
        <Route path="/profit" element={<ShopProfit />} />
        <Route path="/daily-report" element={<DailySaleReport />} />
        <Route path="/expenses" element={<ExpensesList />} />
        <Route path="/expenses-add" element={<ExpensesAdd />} />
        <Route path="/item-refund" element={<RefundPage />} />
        <Route path="/sale-return" element={<SaleReturnItem />} />
        <Route path="/sale-return-list" element={<SaleReturnList />} />
        <Route path="/daily-reports" element={<DailyReports />} />
        <Route path="/vip-customers" element={<VIPCustomerPage />} />
        {/* <Route path="/vip-history" element={<VipCustomerHistory />} /> */}
        <Route path="/main-print" element={<MainStreamPrinter />} />
        <Route path="/check-in-out" element={<CheckProductInOut />} />
        {/* <Route path="/adjust" element={< StockAdjustment/>} /> */}
      
      </Routes>
    </Layout>
  );
}

export default App;
