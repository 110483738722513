import React,{ useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "@material-tailwind/react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { useGetMontlyReportsQuery } from "../../ApiService/MontlyReportsApiSlice";

const ShopProfit = () => {
  const navigate = useNavigate();
  useEffect(()=>{
    const auth = localStorage.getItem('auth');
    if(!auth){
      navigate("/login");
    }
  },[])

  const [month,setMonth] = useState("");
  const [year,setYear] = useState("");
  const [reportMonth,setReportMonth] = useState([]);
  const [reportYear,setReportYear] = useState([]);

  const months = [
    {
      id: 1,
      fullname: "January",
      mm: "ဇန်နဝါရီလ"
    },
    {
      id: 2,
      fullname: "February",
      mm: "ဖေဖော်ဝါရီလ"
    },
    {
      id: 3,
      fullname: "March",
      mm: "မတ်လ"
    },
    {
      id: 4,
      fullname: "April",
      mm:"ဧပြီလ"
    },
    {
      id: 5,
      fullname: "May",
      mm: "မေလ"
    },
    {
      id: 6,
      fullname: "June",
      mm: "ဇွန်လ"
    },
    {
      id: 7,
      fullname: "July",
      mm: "ဇူလိုင်လ"
    },
    {
      id: 8,
      fullname: "August",
      mm: "ဩဂုတ်လ"
    },
    {
      id: 9,
      fullname: "September",
      mm: "စက်တင်ဘာလ"
    },
    {
      id: 10,
      fullname: "October",
      mm:  "အောက်တိုဘာလ"
    },
    {
      id: 11,
      fullname: "November",
      mm:"နိုဝင်ဘာလ"
    },
    {
      id: 12,
      fullname: "December",
      mm: "ဒီဇင်ဘာလ"
    },

  ];

  const years = [
    "2023",
    "2024",
    "2025",
    "2026",
    "2027",
    "2028",
    "2029",
    "2030",
  ];
  // const [reportLoading,setReportLoading] = useState(false)
  const shop = localStorage.getItem('shop_id');

  const { data:montlyReport, isLoading:reportLoading, isError, isSuccess,refetch } = useGetMontlyReportsQuery(shop);

  const columns = !reportLoading
  ? [
      { field: "No", headerName: "No.", width: 50 },
      {
        field: "month_year",
        headerName: "Month",
        sortable: true,
        flex: 1,
        renderCell:(params) =>{
          const index = parseInt(params.value.split('-')[0])
          const y = parseInt(params.value.split('-')[1])
         
          const month = months.filter((month) => month.id === index)
          
          return (
          <p className="">
           { month[0].mm + ' ( '+ y + ' ) ' }
          </p>)
        }
        
      },
      {
        field: "vouchercount",
        headerName: "Voucher Count",
        flex: 1,
      },
      {
        field: "saleamount",
        headerName: "Sales Amount",
        flex: 1,
      },
      {
        field: "cash",
        headerName: "Cash Amount",
        flex: 1,
        renderCell: (params) =>{
          const mainAcc = params.row;
          const cash_amount = parseInt(mainAcc.saleamount) - parseInt(mainAcc.billamount);
          const defaultVal = params.row.expense.length;
          const incomeAmount = defaultVal === 0 ? 0 : params.row.expense[0].income_amount
          const expenseAmount = defaultVal === 0 ? 0 : params.row.expense[0].expense_amount
          return (
            <h1 className={`font-poppins p-1 rounded-lg  ${incomeAmount  < expenseAmount ? 'text-red-400 bg-red-100' : 'text-green-800 bg-green-100'}`} onClick={() => console.log(params.row)}>
              { cash_amount }
            </h1>
          )
        },
      },
      {
        field: "billamount",
        headerName: "Bill Amount",
        flex: 1,
      },
      {
        field: "expense",
        headerName: "Expense Amount",
        sortable: true,
        flex: 1,
        renderCell:(params) =>{
          const defaultVal = params.row.expense.length;
          return (
          <p className="font-poppins">
          { defaultVal === 0 ? 0 : params.row.expense[0].expense_amount}
          </p>)
        }
      },
      {
        field: "income",
        headerName: "Income Amount",
        sortable: true,
        flex: 1,
        renderCell:(params) =>{
          const defaultVal = params.row.expense.length;
          const incomeAmount = defaultVal === 0 ? 0 : params.row.expense[0].income_amount
          const expenseAmount = defaultVal === 0 ? 0 : params.row.expense[0].expense_amount
          return (
          <p className="font-poppins">
            { defaultVal === 0 ? 0 : params.row.expense[0].income_amount}
          </p>)
        }
      },
      {
        field: "total",
        headerName: "Total Amount",
        sortable: true,
        flex: 1,
        renderCell: (params) =>{
          const mainAcc = params.row;
          const defaultVal = params.row.expense.length;
          const incomeAmount = defaultVal === 0 ? 0 : params.row.expense[0].income_amount
          const expenseAmount = defaultVal === 0 ? 0 : params.row.expense[0].expense_amount
          const total_amount = parseInt(mainAcc.saleamount) - parseInt(mainAcc.billamount) - parseInt(expenseAmount) + parseInt(incomeAmount);
          return (
            <h1 className={`font-poppins p-1 rounded-lg  ${incomeAmount < expenseAmount ? 'text-red-400 bg-red-100' : 'text-green-800 bg-green-100'}`} onClick={() => console.log(params.row)}>
              { total_amount }
            </h1>
          )
        },
      },
    ]
  : [];

  const showMonthReportDetials = () =>{
    
  }

  useEffect(() =>{
    if(isSuccess) {
      console.log(montlyReport?.data);
      setReportMonth([...montlyReport?.data.map((sale, i) => ({ ...sale, No: i + 1,id: i + 1 })),])
    }
  },[isSuccess,montlyReport]);

  return (
    <div className="flex flex-col w-full h-full select-none">
      {/* <div className="flex flex-row w-full text-center justify-end items-center h-fit">
        <div className="px-4">
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={years}
            sx={{ width: 200 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Year"
                size="small"
                margin="dense"
              />
            )}
          />
        </div>
        <div className="px-4">
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={months}
            sx={{ width: 200 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Month"
                size="small"
                margin="dense"
              />
            )}
          />
        </div>
      </div> */}
       <div className="flex-row w-full justify-start flex my-2">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
          Montly Reports
        </p>
      </div>
      <Box sx={{ height: 600, width: "100%" }}>
          { isSuccess ? (
            <DataGrid
              rows={ reportMonth }
              loading={reportLoading}
              density="compact"
              // getRowClassName={(params) => {
              //   return params.row.rem_qty < params.row.alert_qty
              //     ? "text-red-400"
              //     : "";
              // }}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              onRowDoubleClick={(params) => showMonthReportDetials(params.row)}
              pageSizeOptions={[5,10,25,50,100]}
              disableRowSelectionOnClick
              slots={{ toolbar: GridToolbar }}
              showCellVerticalBorder
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              //loading
            />
          ) : (
            <div className="flex flex-row w-full h-full items-center justify-center">
              <Spinner />
            </div>
          )}
        </Box>
    </div>
  );
};

export default ShopProfit;
