
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Spinner,IconButton } from "@material-tailwind/react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useGetDebtListQuery,useGetDebitVoucherQuery } from "../../ApiService/SaleVoucherApiSlice";
import { MdRemoveRedEye } from "react-icons/md";
import { CustomerDebitVoucherList } from "../../Components";


const DebitPage = () => {

  const [open,setOpen] = useState(false);
  const shop = localStorage.getItem("shop_id");
  const [openList, setOpenList] = useState(false);
  const {
    data,
    isSuccess,
    isLoading,
    refetch,
  } = useGetDebtListQuery(shop);
  const [debitList, setDebitList] = useState([])
  const [showDetails, setShowDetails] = useState([]);
  const columns = !isLoading
  ? [
      { field: "id", headerName: "No.", width: 50 },
      {
        field: "name",
        headerName: "Name",
        flex: 1,
      },
      {
        field: "phone",
        headerName: "Phone",
        flex: 1,
      },
      {
        field: "totalBill",
        headerName: "Total Bill Amount",
        flex: 1,
      },
      {
        field: "vouchers",
        headerName: "Voucher List",
        sortable: true,
        flex: 1,
      },
      {
        field: "created_at",
        headerName: "Action",
        sortable: true,
        flex: 1,
        renderCell: (params) =>( 
          <IconButton
          onClick={() => showDebitDetails(params.row)}
          className="w-6 h-6 bg-blue-200 p-4"
        >
          <MdRemoveRedEye className="h-4 w-4 text-blue-600 font-extrabold" />
        </IconButton>
        
       ),
      },
    ]
  : [];

  const showDebitDetails = (row) => {
    setShowDetails(row.voucherList);
    setOpenList(true);
  };

  useEffect(() => {
    if (isSuccess) {
      setDebitList([
        ...data?.data.map((sale, i) => ({ ...sale, id: i + 1,name: sale.customer.name,phone: sale.customer.phone, })),
      ]);
    }
  }, [data, isSuccess]);


  return (
    <div className="flex flex-row w-full h-full">
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-row w-full h-fit justify-between items-center">
        <p className="px-4 py-2 font-poppins text-base bg-gray-700 rounded-lg text-white w-fit m-2">
          All Debits List
        </p>
        {/* <div className="flex flex-row w-fit p-2 gap-4 items-center">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="YYYY-MM-DD"
              defaultValue={startDate}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              label="Choose Start Date"
              className="pr-2 w-full"
              slotProps={{ textField: { size: "small" } }}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="YYYY-MM-DD"
              defaultValue={endDate}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              label="Choose End Date"
              className="pr-2 w-full"
              slotProps={{ textField: { size: "small" } }}
            />
          </LocalizationProvider>
          <IconButton
            aria-label="delete"
            size="large"
            onClick={voucherSearchByDate}
          >
            <SearchOutlinedIcon />
          </IconButton>
        </div> */}
      </div>

      {/* Search Result */}
      <div className="flex flex-col w-full h-full">
      
        <Box sx={{ height: 600, width: "100%" }}>
          {isSuccess ? (
            <DataGrid
              rows={ debitList }
              loading={isLoading}
              getRowClassName={(params) => {
                return params.row.rem_qty < params.row.alert_qty
                  ? "text-red-400"
                  : "";
              }}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              onRowDoubleClick={(params) => showDebitDetails(params.row)}
              pageSizeOptions={[5,10,25,50,100]}
              disableRowSelectionOnClick
              slots={{ toolbar: GridToolbar }}
              showCellVerticalBorder
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              //loading
            />
          ) : (
            <div className="flex flex-row w-full h-full items-center justify-center">
              <Spinner />
            </div>
          )}
        </Box>
      </div>
    </div>
    <CustomerDebitVoucherList
        open={openList}
        handleClose={() => setOpenList(false)}
        showDetails={showDetails}
      />
    {/* <DebitDetailDialog open={open} handleClose={()=>setOpen(false)} showDetails= {debitDetail} refetch={refetch} /> */}
  </div>
  )
}

export default DebitPage
