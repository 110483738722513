import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
} from "@mui/material";

const DetailDialog = ({ open, handleClose, showDetails }) => {

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'lg'}>
        <DialogTitle>
          <p className="font-semibold font-poppins text-center text-gray-600 p-4 rounded-xl">
            Product Details
          </p>
        </DialogTitle>
        <DialogContent>
          {/* {JSON.stringify(showDetails)} */}
          
          <div className="flex flex-row">
            <div className="flex-1 p-2">
              <img
                className="h-[400px] w-[400px] rounded-lg border-2"
                src={`${localStorage.getItem("url")}/${showDetails.img}`}
                alt="nature"
              />
            </div>
            <div className="flex-1 flex-col font-poppins overflow-y-auto scrollbar-hide items-center p-2">
              <div className="flex flex-row justify-between items-center my-2">
                <div className="text-gray-600"> Barcode</div>
                <div className="mx-4 text-gray-700">{showDetails.barcode}</div>
              </div>
              <div className="flex flex-row justify-between items-center my-2">
                <div className="text-gray-600"> Name</div>
                <div className="mx-4 text-gray-700">{showDetails.name}</div>
              </div>
              <div className="flex flex-row justify-between items-center my-2">
                <div className="text-gray-600"> Category</div>
                <div className="mx-4 text-gray-700">{showDetails.categoryName}</div>
              </div>
              <div className="flex flex-row justify-between items-center my-2">
                <div className="text-gray-600"> Unit </div>
                <div className="mx-4 text-gray-700">{showDetails.unitName}</div>
              </div>
              <div className="flex flex-row justify-between items-center my-2">
                <div className="text-gray-600"> Alert Quantity </div>
                <div className="mx-4 text-gray-700">{showDetails.alert_qty}</div>
              </div>
              <div className="flex flex-row justify-between items-center my-2">
                <div className="text-gray-600"> Remain Quantity </div>
                <div className="mx-4 text-gray-700">{showDetails.rem_qty}</div>
              </div>

              <div className="flex flex-row justify-between items-center my-2">
                <div className="text-gray-600"> Sell Price 1 </div>
                <div className="mx-4 text-gray-700">{showDetails.sell_price1}</div>
              </div>
              <div className="flex flex-row justify-between items-center my-2">
                <div className="text-gray-600"> Sell Price 2 </div>
                <div className="mx-4 text-gray-700">{showDetails.sell_price2}</div>
              </div>
              <div className="flex flex-row justify-between items-center my-2">
                <div className="text-gray-600"> Sell Price 3 </div>
                <div className="mx-4 text-gray-700">{showDetails.sell_price3}</div>
              </div>
              <div className="flex flex-row justify-between items-center my-2">
                <div className="text-gray-600"> Expired Date </div>
                <div className="mx-4 text-gray-700">{showDetails?.purchase?.length > 0 ?  showDetails?.purchase[0].expire_date : ""}</div>
              </div>

              {/* {showDetails.purchase?.map((pur) => (
                <p key={pur.created_at}>
                  Expired Date - <span>{pur.expire_date}</span>
                </p>
              ))} */}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DetailDialog;
