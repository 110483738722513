import { ApiSlice } from "./ApiSlice";

export const SaleVoucherApiSlice = ApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getSaleVoucher: builder.query({
            query: () => '/sale-voucher',
            providesTags:['SaleVoucher']
        }),

        getSaleVoucherById: builder.query({
            query: (id) => ({
                url: `/sale-voucher/${id}`,
                method: 'GET',
                
            }),
            providesTags:['SaleVoucher']
        }),

        getDebitVoucher: builder.query({
            query: (id) => `/voucher/bill/${id}`,
            providesTags:['Debit']
        }),

        getSaleVoucherByShop: builder.query({
            query: (id) => `/sale-voucher/shop/${id}`, 
            providesTags:['SaleVoucher']
        }),

        // getSaleVoucherByDate: builder.mutation({
        //     query: ({ shopid,start,end }) => `/sale-voucher/${shopid}/${start}/${end}`, 
        //     method: 'POST',
        //     invalidatesTags:['SaleVoucher']
        // }),

        getSaleVoucherByDate: builder.mutation({
            query: ({ shopid,start,end }) => ({
                url: `/sale-voucher/${shopid}/${start}/${end}`, 
                method: 'POST',
                body:{
                    shopid: shopid,
                }
            }), 
            invalidatesTags:['SaleVoucher']
        }),

        getSaleVoucherDetails: builder.mutation({
            query: ({ shopid,id}) => ({
                url: `/sale-voucher/${shopid}/${id}`,
                method: 'POST',
                body:{
                    shopid: shopid,
                    id: id,
                }
            }), 
            invalidatesTags:['SaleVoucher']
        }),


        addSaleVoucher: builder.mutation({
            query: (body) => ({
                url: '/sale-voucher',
                method: 'POST',
                body,
            }),
            invalidatesTags:['SaleVoucher']
        }),


        editSaleVoucher: builder.mutation({
            query: (body) =>({
                url: `/sale-voucher/${body.get("id")}`,
                method: 'POST',
                body
            }),
            invalidatesTags:['SaleVoucher']
        }),

        updateDebtPayment:builder.mutation({
            query: (body) =>({
                url: `/customer-paid-debt`,
                method: 'POST',
                body
            }),
            invalidatesTags:['Debit'],
        }),

        getDebtList: builder.query({
            query: (id) => `/customer-debt-list/${id}`,
            providesTags:['Debit']
        }),

        getPaidList: builder.query({
            query: (id) => `/customer-paid-list/${id}`,
            providesTags:['Debit']
        }),

        getPaidDetailsList: builder.query({
            query: (body) => `/paid-details/${body.shop}/${body.id}`,
        }),

        deleteSaleVoucher: builder.mutation({
            query: (id) =>({
                url: `/sale-voucher/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags:['SaleVoucher']
        }),
    })
});

export const {
   useGetSaleVoucherQuery,
   useAddSaleVoucherMutation,
   useEditSaleVoucherMutation,
   useDeleteSaleVoucherMutation,
   useGetSaleVoucherByIdQuery,
   useGetSaleVoucherByShopQuery,
   useGetSaleVoucherByDateMutation,
   useGetSaleVoucherDetailsMutation,
   useGetDebitVoucherQuery,
   useUpdateDebtPaymentMutation,
   useGetDebtListQuery,
   useGetPaidListQuery,
   useGetPaidDetailsListQuery,
   usePrefetch,
   
} = SaleVoucherApiSlice