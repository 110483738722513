import React, { useState } from "react";
import { IoMdTrash } from "react-icons/io";
import { AiOutlineMinusCircle,AiOutlinePlusCircle } from "react-icons/ai"


const SellingDataRow = ({ head, rows, removeItem,increaseQty,decreaseQty }) => {
  return (
    <>
      {
        <div className="py-2 overflow-y-auto h-[360px] 2xl:h-[450px]">
          <div className="flex flex-row items-center gap-1 bg-gray-700 text-white">
            {head.map((item) => (
              <div className={`text-lg px-4 py-2 border-r-[1px] ${item.title == "Product Name" ? 'flex-1': "w-28"}`} key={item.id}>
                {item.title}
              </div>
            ))}
          </div>
          {rows !== undefined &&
            rows.map((row,i) => (
              <div
                className="flex flex-row items-center gap-1 bg-white text-gray-600 my-1 border-b-2 overflow-y-auto"
                key={row.created_at}
              >
                <div className=" border-r-[2px] text-base w-28">{row.barcode}</div>
                <div className=" border-r-[2px] text-base flex-1">
                  {row.product_name}
                </div>
                <div className="w-28 border-r-[2px] text-base text-center flex flex-row justify-around items-center cursor-pointer">
                  <p className="p-1 bg-red-200 text-red-500 font-bold rounded-lg active:bg-red-50" onClick={()=>decreaseQty(i,row.qty)}>
                  <AiOutlineMinusCircle/>
                  </p>
                  <span className="font-bold"> {row.qty}</span>
                  <p className="p-1 bg-green-200 text-green-600 font-bold rounded-lg cursor-pointer active:bg-green-50" onClick={()=>increaseQty(i,row.qty)}>
                    <AiOutlinePlusCircle/>
                  </p>
                </div>
                <div className="w-28 border-r-[2px] text-base text-center">
                  {row.unit_name}
                </div>
                <div className="w-28 border-r-[2px] text-base text-center">
                  {row.sell_price}
                </div>
                <div className="w-28 border-r-[2px] text-base text-center">
                  {row.total}
                </div>
                <div className="w-28 border-r-[2px] text-base text-center">
                  <div
                    className="flex flex-row justify-center"
                    onClick={() => removeItem(row)}
                  >
                    <IoMdTrash
                      className="p-1 bg-red-200 text-red-500 rounded-lg cursor-pointer active:bg-red-50 hover:text-red-700 hover:bg-red-100"
                      size={30}
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>
      }
    </>
  );
};

export default SellingDataRow;
