import { TextField, Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const RegisterPage = () => {

  const navigate = useNavigate();
  
    const authKey = localStorage.getItem("auth");
    const token = localStorage.getItem("token");
    
  useEffect(()=>{
    if(authKey && token != null){
      navigate('/dashboard');
    }else{
      navigate('/login')
    }
  },[])

  const hostRef = useRef();
  return (
    <div className="w-full h-screen flex flex-row justify-center items-center">
      <div className="flex flex-col">
        <p> အကောင့်မရှိပါက ဆိုင်ပိုင်ရှင်တွင် အကောင့်အသစ်ပြုလုပ်ပါရန်နှင့် ပြဿနာ တစ်စုံတစ်ရာ ရှိပါက service provider ကို ဆက်သွယ်ရန်။</p>
        {/* <TextField
          autoFocus
          label="Enter Host Name"
          id="outlined-basic"
          variant="outlined"
          sx={{ width: 400 }}
          inputRef={hostRef}
        />
        <br/>
        <Button
          variant="outlined"
          className="my-4"
          onClick={() => {
            localStorage.setItem("url", hostRef.current.value)
            localStorage.setItem("shop", 1)
            localStorage.setItem("user", 1)
          }}
        >
          Outlined
        </Button> */}
      </div>
    </div>
  );
};

export default RegisterPage;
