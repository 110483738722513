import React, { useState } from "react";
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { HiOutlineTrendingUp } from "react-icons/hi";
import { GiCutDiamond } from "react-icons/gi";

import logoImg from "../images/ttmm.png"
import {
  PresentationChartBarIcon,
  Cog6ToothIcon,
} from "@heroicons/react/24/solid";
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { FaSitemap, FaRegMoneyBillAlt } from "react-icons/fa";
import { MdOutlineSupervisorAccount,MdOutlineCompareArrows  } from "react-icons/md";
import { HiDocumentReport } from "react-icons/hi";
import { IoLogoUsd } from "react-icons/io";
import { GiTakeMyMoney } from 'react-icons/gi'
import { BsPieChartFill,BsFillCalendar2DateFill } from "react-icons/bs"
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";


const MaterialSideBar = () => {
  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const menuList = [
    {
      title: "Dashboard",
      icon: PresentationChartBarIcon,
      link: "/dashboard",
      accordion: [],
    },
    {
      title: "Customers",
      icon: MdOutlineSupervisorAccount,
      link: "/customer",
      accordion: [],
    },
    {
      title: "Selling",
      icon: IoLogoUsd,
      link: "/selling",
      accordion: [],
    },
    {
      title: "Products",
      icon: FaSitemap,
      link: "/product",
      accordion: [],
    },
    {
      title: "Sale Return",
      icon: HiOutlineSwitchHorizontal ,
      link: "/sale-return",
      accordion: [
        {
          name: "Sale Return Product",
          link: "/sale-return",
        },
        {
          name: "Sale Return Record",
          link: "/sale-return-list",
        },
      ],
    },
    {
      title: "Daily Sale Product",
      icon: BsFillCalendar2DateFill,
      link: "/daily-report",
      accordion: [],
    },
    {
      title: "Voucher Reports",
      icon: HiDocumentReport,
      link: "/sale-reports",
      accordion: [],
    },
    {
      title: "Debts",
      icon: GiTakeMyMoney,
      link: "/debit",
      accordion: [
        {
          name: "Debt Paid",
          link: "/debt-paid",
        },
        {
          name: "All Debt List",
          link: "/debit",
        },
        {
          name: "All Paid List",
          link: "/paid",
        },
      ],
    },
    {
      title: "Check Proudct In Out",
      icon: MdOutlineCompareArrows,
      link: "/check-in-out",
      accordion: [],
    },
    {
      title: "Daily Reports",
      icon: HiOutlineTrendingUp,
      link: "/daily-reports",
      accordion: [],
    },
    {
      title: "Monthly Reports",
      icon: BsPieChartFill,
      link: "/profit",
      accordion: [],
    },
    {
      title: "VIP Customer Reports",
      icon: GiCutDiamond ,
      link: "/vip-customers",
      accordion: [],
    },
    {
      title: "Expenses",
      icon: FaRegMoneyBillAlt,
      link: "/expenses",
      accordion: [
        {
          name: "Add Expenses",
          link: "/expenses-add",
        },
        {
          name: "Expenses List",
          link: "/expenses",
        },
      ],
    },
    {
      title: "Account",
      icon: Cog6ToothIcon,
      link: "/account",
      accordion: [],
    },
   
   
    // {
    //   title: "Refund",
    //   icon: RiRefund2Line,
    //   link: "/item-refund",
    //   accordion: [],
    // },
  ];

  return (
    <div className="w-12 md:w-[250px] md:block h-full font-poppins z-30">
      <Card className="w-full h-full max-w-[250px] shadow-xl shadow-white-900/5 bg-[#1b2c3e]">
        <List className="text-white">
          <ListItem className="p-3 group">
            <ListItemPrefix>
              <img src={logoImg} alt="Shop logo"/>
              {/* <PresentationChartBarIcon
                className="h-5 w-5 group-hover:text-[#1b2c3e]"
                color="white"
              /> */}
            </ListItemPrefix>
            <Typography
              color="white"
              className="mr-auto font-normal text-xl group-hover:text-[#1b2c3e]"
            >
              TTMM
            </Typography>
          </ListItem>
          <hr className="my-2 border-white-50" />

          {menuList.map((menu, i) => (
            <div key={uuidv4()}>
              {menu.accordion.length == 0 ? (
                <Link to={menu.link} key={menu.title}>
                  <ListItem
                    className="border-b-0 p-3 group"
                    selected={open === i + 1}
                  >
                    <ListItemPrefix>
                      <menu.icon
                        className="h-5 w-5 group-hover:text-[#1b2c3e]"
                        color="white"
                      />
                    </ListItemPrefix>
                    <Typography
                      color="white"
                      className="mr-auto font-normal text-sm group-hover:text-[#1b2c3e]"
                    >
                      {menu.title}
                    </Typography>
                  </ListItem>
                </Link>
              ) : (
                <Accordion
                  open={open === i + 1}
                  icon={
                    <ChevronDownIcon
                      strokeWidth={2.5}
                      className={`mx-auto h-4 w-4 transition-transform ${
                        open === i + 1 ? "rotate-180" : ""
                      }`}
                    />
                  }
                >
                  <ListItem className="p-0 group" selected={open === i + 1}>
                    <AccordionHeader
                      onClick={() => handleOpen(i + 1)}
                      className="border-b-0 p-3 group"
                    >
                      <ListItemPrefix>
                        <menu.icon
                          className="h-5 w-5 group-hover:text-[#1b2c3e]"
                          color="white"
                        />
                      </ListItemPrefix>
                      <Typography
                        color="white"
                        className="mr-auto font-normal text-sm group-hover:text-[#1b2c3e]"
                      >
                        {menu.title}
                      </Typography>
                    </AccordionHeader>
                  </ListItem>
                  <AccordionBody
                    className={
                      open == i + 1 ? "block py-1 text-white" : "hidden"
                    }
                  >
                    <List className="p-0">
                      {menu.accordion.map((accor, j) => (
                        <Link to={accor.link} key={accor.link}>
                          <ListItem className="text-white text-sm group">
                            <ListItemPrefix>
                              <ChevronRightIcon
                                strokeWidth={3}
                                className="h-3 w-5 group-hover:text-[#1b2c3e]"
                              />
                            </ListItemPrefix>
                            {accor.name}
                          </ListItem>
                        </Link>
                      ))}
                    </List>
                  </AccordionBody>
                </Accordion>
              )}
            </div>
          ))}
        </List>
      </Card>
    </div>
  );
};

export default MaterialSideBar;
