import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Spinner, IconButton } from "@material-tailwind/react";
import { useGetProdcutByStoreQuery, useAddRefundMutation, useGetRefundRecordQuery } from "../../ApiService/ProductApiSlice";
import {
  MdOutlineRemoveCircle,
  MdOutlineAddCircle,
  MdArrowCircleRight,
} from "react-icons/md";
import { MyToolTip } from "../../Components";

const SaleReturnItem = () => {
  const {
    data: products,
    isLoading: productsLoading,
    isSuccess: productsSuccess,
    refetch: reloadProducts
  } = useGetProdcutByStoreQuery(localStorage.getItem("shop_id"));

  const [addRefund, refundResult] = useAddRefundMutation();
  const { refetch} = useGetRefundRecordQuery(localStorage.getItem("shop_id"));

  const [lstProducts, setProductList] = useState([]);
  // const [open, setOpen] = useState(false);
  // const [detial, setDetail] = useState({});



  const columns = productsSuccess
    ? [
        {
          field: "No",
          headerName: "No.",
          width: 50,
          headerAlign: "center",
          align: "center",
        },
        {
          field: "name",
          headerName: "Product Name",
          flex: 1,
          headerAlign: "left",
          align: "left",
        },
        {
          field: "sell_price1",
          headerName: "Sell Price",
          width: 100,
          headerAlign: "center",
          align: "center",
        },

        {
          field: "refund",
          headerName: "Refund Qty",
          sortable: true,
          width: 200,
          headerAlign: "center",
          align: "center",
          renderCell: (params) => {
            return (
              <div className="flex flex-row items-center justify-between">
                <MyToolTip style={`bg-green-500`} content={"remove"}>
                  <IconButton
                    onClick={() => decreaseItem(params.row) }
                    className="w-6 h-6 bg-green-200 p-4"
                  >
                    <MdOutlineRemoveCircle className="h-4 w-4 text-red-600 font-extrabold" />
                  </IconButton>
                </MyToolTip>

                <p className="text-black text-lg mx-4"> {params.row.refundQty} </p>

                <MyToolTip style={`bg-green-500`} content={"add"}>
                  <IconButton
                    onClick={() => increaseItem(params.row)}
                    className="w-6 h-6 bg-green-200 p-4"
                  >
                   { <MdOutlineAddCircle className="h-4 w-4 text-green-600 font-extrabold" />}
                  </IconButton>
                </MyToolTip>
              </div>
            );
          },
        },
        {
          field: "action",
          headerName: "Add Store",
          width: 100,
          headerAlign: "center",
          align: "center",
          renderCell: (params) => {
            return (
              <div className="flex flex-row items-center justify-center">
                <MyToolTip style={`bg-green-500`} content={"Add Store"}>
                  <IconButton
                    onClick={() => addToRefund(params.row)}
                    className="w-6 h-6 bg-green-200 p-4"
                  >
                   { refundResult.isLoading ? <Spinner/> : <MdArrowCircleRight className="h-4 w-4 text-blue-600 font-extrabold" />}
                  </IconButton>
                </MyToolTip>
                
              </div>
            );
          },
        },
      ]
    : [];

    const increaseItem = (row) => {
      setProductList(
        lstProducts.map((p) => p.id === row.id  ? {...p,refundQty: p.refundQty + 1 }: {...p})
      );
    };
  
    const decreaseItem = (row) => {
      setProductList(
        lstProducts.map((p) => p.id === row.id && p.refundQty > 0 ? {...p,refundQty: p.refundQty - 1 }: {...p})
      );
    };
  
    const addToRefund = async(row) => {

      if(refundResult.isLoading) return; 
      const shop = localStorage.getItem('shop_id');
      const user =JSON.parse(localStorage.getItem('user'));

      const body = {
        shop_id: shop,
        product_id: row.id,
        qty:  row.refundQty,
        price: row.refundQty * row.sell_price1,
        user_id:user.id,
        saas: "futuretechmm",
      }

     await addRefund(body);
     reloadProducts();
     refetch();
     setProductList(
      lstProducts.map((p) => p.id === row.id  ? {...p,refundQty: 0 }: {...p})
    );
    };

  useEffect(() => {
    if (productsSuccess) {
      setProductList(
        products?.data?.map((p, i) => {
          return {
            No: i + 1,
            id: p.product.id,
            img: p.product.img,
            rem_qty: p.qty,
            currency: p.product.currency.ChangePrice,
            name: p.product.name,
            categoryName: p.product.category.name,
            alert_qty: p.product.alert_qty,
            unitName: p.product.unit.name,
            barcode: p.product.barcode,
            sell_price1:
              Math.ceil(
                ((p.product.purchase.price +
                  (p.product.purchase.price * p.product.remark) / 100) *
                  p.product.currency.ChangePrice) /
                  100
              ) * 100,
            sell_price2: p.product.sell_price2,
            sell_price3: p.product.sell_price3,
            purchase: p.product.purchase,
            refundQty: 0,
          };
        })
      );
    }
  }, [products, productsSuccess]);
  return (
    <div className="flex flex-col max-h-full">
      <div className="flex-row w-full justify-between flex">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold mb-4">
          Sale Return
        </p>

      </div>
      <div className="flex flex-col w-full">
        <Box sx={{ height: 600, width: "100%" }}>
          {!productsLoading ? (
            productsSuccess && (
              <DataGrid
                rows={lstProducts}
                loading={productsLoading}
                getRowClassName={(params) => {
                  return params.row.rem_qty < params.row.alert_qty
                    ? "text-red-400"
                    : "";
                }}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                disableRowSelectionOnClick
                slots={{ toolbar: GridToolbar }}
                showCellVerticalBorder
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
                //loading
              />
            )
          ) : (
            <div className="flex flex-row w-full h-full justify-center items-center">
              <Spinner color="purple" className="w-16 h-16" />
            </div>
          )}
        </Box>
      </div>
      {/* <DetailDialog
      open={open}
      handleClose={() => setOpen(false)}
      showDetails={detial}
    /> */}
    </div>
  );
};

export default SaleReturnItem;
