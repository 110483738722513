import React, { useState, useRef, useEffect } from "react";
import { MyToolTip } from "../../Components";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import {
  useGetCustomerQuery,
  useAddCustomerMutation,
  useEditCustomerMutation,
  useDeleteCustomerMutation,
} from "../../ApiService/CustomerApiSlice";
import {
  Button,
  Input,
  Typography,
  Drawer,
  IconButton,
  Spinner,
} from "@material-tailwind/react";
import { IoAddCircleOutline } from "react-icons/io5";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { BiTrash } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";
import Swal from "sweetalert2";
import { useNavigate, useLocation } from "react-router-dom";

const AddCustomer = () => {

  const [editCusIdState, setEditCusIdState] = useState();
  
  const navigate = useNavigate();
  useEffect(()=>{
    const auth = localStorage.getItem('auth');
    if(!auth){
      navigate("/login");
    }
  },[])

  const [open, setOpen] = useState(false);
  const customerRef = useRef();
  const customerPhoneRef = useRef();
  const addressRef = useRef();
  const editCustomerRef = useRef();
  const editCustomerPhoneRef = useRef();
  const editAddressRef = useRef();
  const shopId = localStorage.getItem("shop_id");
  
  const header = [
    {
      field: "name",
      headerName: "Name",
      width: 250,
      editable: false,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      width: 150,
      editable: true,
      renderCell: (params)=>{
        return (
          <h1 className="font-poppins">{params.value}</h1>
        )
      }
    },
    {
      field: "address",
      headerName: "Address",
      width: 460,
      editable: false,
      renderCell: (params) => (
        <h1 className="font-poppins" onClick={() => console.log(params.row)}>
          {params.value}
        </h1>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <div className="flex flex-row gap-4 justify-between">
          {/* <div> */}
          <MyToolTip style={`bg-green-500`} content={"Edit"}>
            <IconButton
              onClick={() => openDrawer(params.row)}
              className="w-6 h-6 bg-green-200 p-4"
            >
              <FaEdit className="h-4 w-4 text-green-600 font-extrabold" />
            </IconButton>
          </MyToolTip>
          <MyToolTip style={`bg-red-500`} content={"Delete"}>
            <IconButton
              // onClick={() =>console.log(params.row.id)}
              onClick={() => confirmDelete(params.row.id)}
              variant="text"
              className="w-6 h-6 bg-red-200 p-4"
            >
              <BiTrash className="h-4 w-4 text-red-600" />
            </IconButton>
          </MyToolTip>
        </div>
      ),
    },
  ];

  // api calling ------
  const { data, isSuccess } = useGetCustomerQuery(shopId);
  const [addCustomer, addCusResult] = useAddCustomerMutation();
  const [editCustomer, editCusResult] = useEditCustomerMutation();
  const [deleteCustomer] = useDeleteCustomerMutation();
  //    Drawer
  const openDrawer = async (e) => {
    setEditCusIdState(e.id);
    editCustomerRef.current.value = e.name;
    editCustomerPhoneRef.current.value = e.phone;
    editAddressRef.current.value = e.address;
    setOpen(true);
  };
  const closeDrawer = () => setOpen(false);

  //--------
  const pressEnterAddCustomer = (event) => {
    if (customerRef.current.value !== "") {
      if (event.key === "Enter") {
        addCustomerHandler();
      }
    }
  };
  const addCustomerHandler = async (e) => {
    e.preventDefault();
    let body = {
      shop_id: localStorage.getItem("shop_id"),
      name: customerRef.current.value,
      phone: customerPhoneRef.current.value,
      address: addressRef.current.value,
    };
    await addCustomer(body);
    customerRef.current.value = "";
    customerPhoneRef.current.value = "";
    addressRef.current.value = "";
  };
  // edit customer
  const editCustomerHandler = async (e) => {
    //  e.preventDefault();
    let body = {
      id: editCusIdState,
      shop_id: localStorage.getItem("shop_id"),
      name: editCustomerRef.current.value,
      phone: editCustomerPhoneRef.current.value,
      address: editAddressRef.current.value,
      status: "1",
      note: "1",
    };
    console.log(body);
    await editCustomer(body);
    editCustomerRef.current.value = "";
    editCustomerPhoneRef.current.value = "";
    editAddressRef.current.value = "";
    closeDrawer();
  };

  const deleteCustomerHandler = async (id) => {
    console.log(id);
    await deleteCustomer(id);

  };
  const confirmDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((deleteCusResult) => {
      if (deleteCusResult.isConfirmed) {
        console.log(id);
        deleteCustomerHandler(id);
        Swal.fire("Deleted!", "Your category has been deleted.", "success");
      }
    });
  };

  return (
    <div className="flex flex-col gap-4 px-16 max-h-full">
      <Drawer placement="right" open={open} onClose={closeDrawer}>
        <div className="mb-2 flex items-center justify-between p-4">
          <Typography variant="h5" color="blue-gray">
            Edit Customer
          </Typography>
          <IconButton variant="text" color="blue-gray" onClick={closeDrawer}>
            <XMarkIcon strokeWidth={2} className="h-5 w-5" />
          </IconButton>
        </div>
        <form className="flex flex-col gap-6 p-4">
          <Input
            type="text"
            label="Customer"
            defaultValue={open ? editCustomerRef.customer : ""}
            inputRef={editCustomerRef}
          />
          <Input
            type="text"
            label="Phone"
            defaultValue={open ? editCustomerPhoneRef.customerPhone : ""}
            inputRef={editCustomerPhoneRef}
          />
          <Input
            type="text"
            label="Address"
            defaultValue={open ? editAddressRef.customerPhone : ""}
            inputRef={editAddressRef}
          />
          <Button onClick={editCustomerHandler}>
            {editCusResult.isLoading ? <Spinner /> : "Save"}
          </Button>
        </form>
      </Drawer>
      <div className="flex-row w-full justify-start flex">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
          Add New Customer
        </p>
      </div>
      <div className="w-full px-6 py-2 h-22 border-2 border-gray-200 md:flex flex-row gap-2 items-end justify-center rounded-xl">
        <form
          className=" flex flex-1 xl:flex-row md:flex-col gap-2 m-2"
          onSubmit={addCustomerHandler}
        >
          <div className=" flex flex-1 xl:flex-row md:flex-col gap-2 m-2">
            <Input
              size="lg"
              label="Customer Name"
              inputRef={customerRef}
              required
            />
            <Input
              size="lg"
              label="Phone"
              inputRef={customerPhoneRef}
              required
            />
            <Input
              size="lg"
              label="Address"
              inputRef={addressRef}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  pressEnterAddCustomer();
                }
              }}
            />
            <div className="flex-none">
              {
                <Button
                  type="submit"
                  variant="outlined"
                  className="flex items-center gap-3 px-4 py-2 m-1"
                >
                  {addCusResult.isLoading ? (
                    <Spinner color="indigo" />
                  ) : (
                    <IoAddCircleOutline strokeWidth={2} className="h-5 w-5" />
                  )}
                  Add
                </Button>
              }
            </div>
          </div>
        </form>
      </div>
      <div className="flex flex-col w-full">
        <Box sx={{ height: 400, width: "100%" }}>
          {isSuccess ? (
            <DataGrid
              rows={data?.data}
              columns={header}
              density="compact"
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              // }
              pageSizeOptions={[5,10,25,50,100]}
              disableRowSelectionOnClick
              slots={{ toolbar: GridToolbar }}
              showCellVerticalBorder
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              //loading
            />
          ) : (
            <></>
          )}
        </Box>
      </div>
      <div className="h-6"></div>
    </div>
  );
};

export default AddCustomer;
