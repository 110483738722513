import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Input,
  Typography,
  Drawer,
  Spinner,
  IconButton,
} from "@material-tailwind/react";
// import { IconButton } from "@mui/material";
import { MyToolTip } from "../../Components";
import { IoAddCircleOutline } from "react-icons/io5";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { BiTrash } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";
import Swal from "sweetalert2";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { AiOutlineStop } from "react-icons/ai";
import {
  useEditExpenseMutation,
  useAddExpenseMutation,
  useDeleteExpenseMutation,
  useGetExpensesByDateMutation,
} from "../../ApiService/ExpenseApiSlice";
import { Autocomplete, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Snackbar from "@mui/material/Snackbar";
import { useGetMontlyReportsQuery } from "../../ApiService/MontlyReportsApiSlice";
import { useGetProfitLossQuery } from "../../ApiService/MontlyReportsApiSlice";

const ExpensesAdd = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const auth = localStorage.getItem("auth");
    if (!auth) {
      navigate("/login");
    }
  }, []);

  const [openSnack, setOpenSnack] = React.useState(false);

  const handleOpen = () => {
    setOpenSnack(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const shop = localStorage.getItem("shop_id");
  const { refetch: monthlyRefetch } = useGetMontlyReportsQuery(shop);
  const { refetch: profitLoss } = useGetProfitLossQuery(shop);

  const about = [
    {
      id: 1,
      label: "Expenses",
    },
    {
      id: 2,
      label: "Income",
    },
    {
      id: 3,
      label: "Opening",
    },
    {
      id: 4,
      label: "Transfer",
    },
  ];



  const [open, setOpen] = useState(false);
  const [note, setNote] = useState(1);
  const [expenseId, setExpenseId] = useState(0);
  const [expenseList, setExpenseList] = useState([]);
  // const [expenseData,setExpenseData] = useState([]);

  const [getexpenseByDate, getexpenseByDateResult] =
    useGetExpensesByDateMutation();

  const [editexpense, editExpanseResult] = useEditExpenseMutation();
  const [addExpense, addExpenseResult] = useAddExpenseMutation();
  const [deleteExpense, deleteExpenseResult] = useDeleteExpenseMutation();

  const contentRef = useRef("");
  const amountRef = useRef(0);
  var today = new Date();
  const modifyDate = today.getDate() < 10 ? "0" + today.getDate(): today.getDate();
  const date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + modifyDate;

  const [startDate, setStartDate] = useState(dayjs(date));
  const [endDate, setEndDate] = useState(dayjs(date));

  const editcontentRef = useRef("");
  const editamountRef = useRef(0);
  const closeDrawer = () => setOpen(false);

  const header = [
    {
      field: "No",
      headerName: "No",
      width: 100,
      editable: false,
    },
    {
      field: "content",
      headerName: "Use Reason",
      flex: 1,
      editable: false,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      editable: false,
    },
    {
      field: "note",
      headerName: "Description",
      width: 150,
      editable: false,
      renderCell: (params) => {
        let remark = "";
        let remarkNote=1;
        if(params.value == 1){
            remark = "Expense";
            remarkNote = 1;
        }else if(params.value == 2){
          remark = "Income";
          remarkNote = 2;
        }else if(params.value == 3){
          remark = "Opening";
          remarkNote = 3;
        }else{
          remark = "Transfer";
          remarkNote = 4;
        }
        
        return (
        <h1
          className={`font-poppins p-1 rounded-lg  ${
            remarkNote == 1 || remarkNote == 4
              ? "text-red-400 bg-red-100"
              : "text-green-800 bg-green-100"
          }`}
          onClick={() => console.log(params.row)}
        >
          {remark}
        </h1>
      )},
    },
    {
      field: "created_at",
      headerName: "Date",
      sortable: true,
      width: 150,
      renderCell: (params) => <p>{params.value.split("T")[0]}</p>,
    },
    {
      field: "updated_at",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        const entryDate = params.value.split("T")[0];

        return entryDate != date ? (
          <div>
            <AiOutlineStop />
          </div>
        ) : (
          <div className="flex flex-row gap-4 justify-between">
            {/* <div> */}
            <MyToolTip style={`bg-green-500`} content={"Edit"}>
              <IconButton
                onClick={() => openDrawer(params.row)}
                className="w-6 h-6 bg-green-200 p-4"
              >
                <FaEdit className="h-4 w-4 text-green-600 font-extrabold" />
              </IconButton>
            </MyToolTip>
            <MyToolTip style={`bg-red-500`} content={"Delete"}>
              <IconButton
                // onClick={() =>console.log(params.row.id)}
                onClick={() => confirmDelete(params.row.id)}
                variant="text"
                className="w-6 h-6 bg-red-200 p-4"
              >
                <BiTrash className="h-4 w-4 text-red-600" />
              </IconButton>
            </MyToolTip>
          </div>
        );
      },
    },
  ];
  const openDrawer = async (e) => {
    setExpenseId(e.id);
    editcontentRef.current.value = e.content;
    editamountRef.current.value = e.amount;
    setNote(null);
    setOpen(true);
  };

  const editExpense = async (e) => {
    const user = JSON.parse(localStorage.getItem("user"));
    console.log(user);
    if (note === null) {
      handleOpen();
      return;
    }
    const body = {
      id: expenseId,
      user_id: user.id,
      shop_id: shop,
      content: editcontentRef.current.value,
      amount: editamountRef.current.value,
      note: note,
      status: "0",
    };
    await editexpense(body);
  };
  const deleteExpenses = async (e) => {
    await deleteExpense(e);
  };

  const addExpeseAmount = async (e) => {
    e.preventDefault();
    const user = JSON.parse(localStorage.getItem("user"));
    console.log(user);
    if (note === null) {
      handleOpen();
      return;
    }
    const body = {
      user_id: user.id,
      shop_id: shop,
      content: contentRef.current.value,
      amount: amountRef.current.value,
      note: note,
      status: "0",
    };

    console.log(body);
    await addExpense(body);
    monthlyRefetch();
    profitLoss();
    const start = startDate.format("YYYY-MM-DD");
    const end = endDate.format("YYYY-MM-DD");

    const item = {
      id: shop,
      start: start,
      end: end,
    };
    getexpenseByDate(item);
  };

  const searchByDate = () => {
    const start = startDate.format("YYYY-MM-DD");
    const end = endDate.format("YYYY-MM-DD");

    const body = {
      id: shop,
      start: start,
      end: end,
    };
    getexpenseByDate(body);
  };

  const selectExpenseReason = (val, e) => {
    if (val === null) {
      setNote(null);
    } else {
      setNote(val.id);
    }
  };

  const confirmDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((deleteCusResult) => {
      if (deleteCusResult.isConfirmed) {
        console.log(id);
        deleteExpenses(id);
        Swal.fire("Deleted!", "Your Expense has been deleted.", "success");
      }
    });
  };

  useEffect(() => {
    searchByDate();
  }, []);

  useEffect(() => {
    console.log(getexpenseByDateResult.data);
    if (getexpenseByDateResult.isSuccess) {
      setExpenseList(
        getexpenseByDateResult?.data.data.map((sale, i) => ({
          ...sale,
          No: i + 1,
          id: i + 1,
        }))
      );
    }
  }, [getexpenseByDateResult]);

  return (
    <div className="flex flex-col gap-4 px-16 max-h-full">
      <Drawer placement="right" open={open} onClose={closeDrawer}>
        <div className="mb-2 flex items-center justify-between p-4">
          <Typography variant="h5" color="blue-gray">
            Edit Expense
          </Typography>
          <IconButton variant="text" color="blue-gray" onClick={closeDrawer}>
            <XMarkIcon strokeWidth={2} className="h-5 w-5" />
          </IconButton>
        </div>
        <form className="flex flex-col gap-6 p-4">
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={about}
            sx={{ width: 265 }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, newValue) => {
              // console.log(newValue)
              if (newValue !== null) {
                selectExpenseReason(newValue, event);
              } else {
                selectExpenseReason(newValue, event);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Year"
                size="small"
                margin="dense"
              />
            )}
          />
          <Input
            type="text"
            label="About"
            defaultValue={open ? contentRef.customer : ""}
            inputRef={editcontentRef}
          />
          <Input
            type="number"
            label="Phone"
            defaultValue={open ? amountRef.customerPhone : ""}
            inputRef={editamountRef}
          />
          <Button onClick={editExpense}>
            {editExpanseResult.isLoading ? <Spinner /> : "Save"}
          </Button>
        </form>
      </Drawer>
      <div className="flex-row w-full justify-between flex">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
          Add Expense Income
        </p>
        <div className="flex flex-row w-fit p-2 gap-4 items-center">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="YYYY-MM-DD"
              defaultValue={startDate}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              label="Choose Start Date"
              className="pr-2 w-full"
              slotProps={{ textField: { size: "small" } }}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="YYYY-MM-DD"
              defaultValue={endDate}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              label="Choose End Date"
              className="pr-2 w-full"
              slotProps={{ textField: { size: "small" } }}
            />
          </LocalizationProvider>
          <IconButton
            aria-label="delete"
            size="large"
            onClick={searchByDate}
            className="w-6 h-6 bg-blue-400 p-4"
          >
            <SearchOutlinedIcon />
          </IconButton>
        </div>
      </div>
      <div className="w-full px-6 py-2 h-22 border-2 border-gray-200 md:flex flex-row gap-2 items-end justify-center rounded-xl">
        <form
          className=" flex flex-1 xl:flex-row md:flex-col gap-2 m-2"
          onSubmit={addExpeseAmount}
        >
          <div className=" flex flex-1 xl:flex-row md:flex-col gap-2 m-2 items-center">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={about}
              sx={{ width: 500 }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue) => {
                // console.log(newValue)
                if (newValue !== null) {
                  selectExpenseReason(newValue, event);
                } else {
                  selectExpenseReason(newValue, event);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select"
                  size="small"
                  margin="dense"
                />
              )}
            />
            <Input size="md" label="About" inputRef={contentRef} required />
            <Input
              size="md"
              label="Amount"
              type="number"
              inputRef={amountRef}
              required
            />
            <div className="flex-none">
              {
                <Button
                  type="submit"
                  variant="outlined"
                  className="flex items-center gap-3 px-4 py-2 m-1"
                >
                  {addExpenseResult.isLoading ? (
                    <Spinner color="indigo" />
                  ) : (
                    <IoAddCircleOutline strokeWidth={2} className="h-5 w-5" />
                  )}
                  Add
                </Button>
              }
            </div>
          </div>
        </form>
      </div>
      <div className="flex flex-col w-full">
        <Box sx={{ height: 500, width: "100%" }}>
          {getexpenseByDateResult.isSuccess ? (
            <DataGrid
              rows={expenseList}
              columns={header}
              density="compact"
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              // }
              pageSizeOptions={[5, 10, 25, 50, 100]}
              disableRowSelectionOnClick
              slots={{ toolbar: GridToolbar }}
              showCellVerticalBorder
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              //loading
            />
          ) : (
            <></>
          )}
        </Box>
      </div>
      <Snackbar
        open={openSnack}
        autoHideDuration={2000}
        onClose={handleClose}
        message="Please choose Expense or Income!"
      />
    </div>
  );
};

export default ExpensesAdd;
