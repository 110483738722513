import React, { useState, useRef, forwardRef } from "react";
import Slide from "@mui/material/Slide";
import { v4 as uuidv4 } from "uuid";
import { Dialog, Button } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ProductTransfer = ({ open, handleClose, giveDetails, takeDetails }) => {

    console.log(giveDetails);
    console.log(takeDetails);
    
  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Product Transfer Details
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              OK
            </Button>
          </Toolbar>
        </AppBar>
        <div className="flex flex-row justify-around py-2">
          <div className="border-2 rounded-lg">
          <p className="px-4 py-2 bg-green-400 rounded-tr-lg rounded-tl-lg">Take Product</p>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>No</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Qty</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {giveDetails?.map((detail, i) => (
                    <TableRow
                      key={uuidv4()}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {i + 1}
                      </TableCell>
                      <TableCell>
                        {" "}
                        {detail?.updated_at.split("T")[0].toString()}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {detail?.product?.name}
                      </TableCell>
                      <TableCell> {detail?.qty} </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="border-2 rounded-lg">
          <p className="px-4 py-2 bg-yellow-400 rounded-tr-lg rounded-tl-lg">Transfer Product</p>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>No</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Qty</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {takeDetails?.map((detail, i) => (
                    <TableRow
                      key={uuidv4()}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {i + 1}
                      </TableCell>
                      <TableCell>
                        {" "}
                        {detail?.updated_at.split("T")[0].toString()}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {detail?.product?.name}
                      </TableCell>
                      <TableCell> {detail?.qty} </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ProductTransfer;
