import { ApiSlice } from "./ApiSlice";

export const CustomerApiSlice = ApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCustomer: builder.query({
            query: (id) => '/shop-customer/' + id,
            providesTags:['Customer']
        }),

        addCustomer: builder.mutation({
            query: (body) => ({
                url: '/customer',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['Customer'],
        }),

        editCustomer: builder.mutation({
            query: (body) =>({
                url: `/customer/${body.id}`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['Customer'],
        }),

        deleteCustomer: builder.mutation({
            query: (id) =>({
                url: `/customer/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Customer'],
        }),
    })
});

export const {
   useGetCustomerQuery,
   useAddCustomerMutation,
   useEditCustomerMutation,
   useDeleteCustomerMutation,
} = CustomerApiSlice