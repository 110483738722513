import React,{ useEffect,useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useGetMonthExpenseQuery } from '../../ApiService/ExpenseApiSlice';
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const ExpensesList = () => {
    const navigate = useNavigate();
    useEffect(()=>{
      const auth = localStorage.getItem('auth');
      if(!auth){
        navigate("/login");
      }
    },[])

    const shop = localStorage.getItem('shop_id');

    const { data,isSuccess, isLoading } = useGetMonthExpenseQuery(shop);
    const [expenseList,setExpenseList] = useState([])

    const header = [
      {
        field: "No",
        headerName: "No",
        width: 100,
        editable: false,
      },
      {
        field: "month_year",
        headerName: "Month",
        sortable: true,
        flex: 1,
        renderCell:(params) =>{
          const index = parseInt(params.value.split('-')[0])
          const y = parseInt(params.value.split('-')[1])
         
          const month = months.filter((month) => month.id === index)
          
          return (
          <p className="">
           { month[0].mm + ' ( '+ y + ' ) ' }
          </p>)
        }
      },
      {
        field: "expense_amount",
        headerName: "Expense Amount",
        flex: 1,
        editable: false,
      },
      {
        field: "income_amount",
        headerName: "Income Amount",
        flex: 1,
        editable: false,
        renderCell:(params) =>{
         
         return <p  className={`font-poppins p-1 rounded-lg  ${params.row.status == 1 ?  'text-yellow-700 bg-yellow-00' : 'text-green-800 bg-green-100'}`}>
           { params.row.income_amount }
          </p>;
        }
      },
      {
        field: "note",
        headerName: "Description",
        width: 150,
        editable: false,
        renderCell: (params) => (
          <h1 className={`font-poppins p-1 rounded-lg  ${params.row.income_amount  < params.row.expense_amount ? 'text-red-400 bg-red-100' : 'text-green-800 bg-green-100'}`} onClick={() => console.log(params.row)}>
            {params.row.income_amount  < params.row.expense_amount ? "Expense" : "Income"}
          </h1>
        ),
      },
      
      
    ];

    const months = [
      {
        id: 1,
        fullname: "January",
        mm: "ဇန်နဝါရီလ"
      },
      {
        id: 2,
        fullname: "February",
        mm: "ဖေဖော်ဝါရီလ"
      },
      {
        id: 3,
        fullname: "March",
        mm: "မတ်လ"
      },
      {
        id: 4,
        fullname: "April",
        mm:"ဧပြီလ"
      },
      {
        id: 5,
        fullname: "May",
        mm: "မေလ"
      },
      {
        id: 6,
        fullname: "June",
        mm: "ဇွန်လ"
      },
      {
        id: 7,
        fullname: "July",
        mm: "ဇူလိုင်လ"
      },
      {
        id: 8,
        fullname: "August",
        mm: "ဩဂုတ်လ"
      },
      {
        id: 9,
        fullname: "September",
        mm: "စက်တင်ဘာလ"
      },
      {
        id: 10,
        fullname: "October",
        mm:  "အောက်တိုဘာလ"
      },
      {
        id: 11,
        fullname: "November",
        mm:"နိုဝင်ဘာလ"
      },
      {
        id: 12,
        fullname: "December",
        mm: "ဒီဇင်ဘာလ"
      },
  
    ];
  

    useEffect(() => {
      if(isSuccess){
        setExpenseList([...data?.data.map((sale, i) => ({ ...sale, No: i + 1,id: i + 1 })),])
      }
    },[isSuccess,data]);

  return (
     <div className="flex flex-col gap-4 px-6 max-h-full">
      <div className="flex-row w-full justify-start flex">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
          List Expenses For Month
        </p>
      </div>
      <div className="flex flex-col w-full">
        <Box sx={{ height: 400, width: "100%" }}>
          {isSuccess ? (
            <DataGrid
              rows={expenseList}
              columns={header}
              density='compact'
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              // }
              pageSizeOptions={[5,10,25,50,100]}
              disableRowSelectionOnClick
              slots={{ toolbar: GridToolbar }}
              showCellVerticalBorder
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              //loading
            />
          ) : (
            <></>
          )}
        </Box>
      </div>
    </div>
  )
}

export default ExpensesList
